<div class="parent">
  <div class="back">
    <div class="label"><mat-icon>account_circle</mat-icon> User Management</div>
    <div class="search">
      <input type="text" placeholder="Search Users" [(ngModel)]="searchText" />
    </div>
    <div class="filter">
      <mat-form-field>
        <mat-label style="color: white">Select User Role</mat-label>
        <mat-select placeholder="Select User Role" [(ngModel)]="text" [value]="filterValue"
          (selectionChange)="filterUsersOnRole($event)">
          <mat-option value="all">All Roles</mat-option>
          <mat-option value="admin">Admin</mat-option>
          <mat-option value="designer">Designer</mat-option>
          <mat-option value="manager">Manager</mat-option>
          <mat-option value="agent">Agent</mat-option>
          <mat-option value="saleManager">Sale Manager</mat-option>
          <mat-option value="user">Inactive</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button (click)="onAddAgent()">ADD AGENT</button>
    </div>
  </div>
  <div align="center" *ngIf="users.length === 0" class="mt-2">
    <h1>No Users Found</h1>
  </div>
  <div class="content" *ngIf="users.length > 0">
    <table>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Role</th>
          <th>Delete</th>
          <th>Password</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let u of users | filter: searchText; let i = index">
          <td>{{ u.firstName }}</td>
          <td>{{ u.lastName }}</td>
          <td>{{ u.phone }}</td>
          <td>{{ u.email }}</td>
          <td>
            <mat-select placeholder="Change Role" [value]="u.roles[0]"
              (selectionChange)="onChangeUserRole($event, u._id)">
              <mat-option value="admin">Admin</mat-option>
              <mat-option value="designer">Designer</mat-option>
              <mat-option value="manager">Manager</mat-option>
              <mat-option value="agent">Agent</mat-option>
              <mat-option value="saleManager">Sale Manager</mat-option>
            </mat-select>
          </td>
          <td>
            <mat-icon (click)="onDeleteUser({'value': 'user'}, u._id)">delete</mat-icon>
          </td>
          <td>
            <mat-icon (click)="onPasswordChange(u.email, u.password)">edit</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>