<div class="d-flex flex-column">
  <button mat-button class="pdf-button" (click)="openGenerateModal()">
    Back
  </button>
  <button mat-button class="pdf-button" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
    Generate PDF
  </button>
  <div class="d-flex flex-column p-5 invoice justify-content-s content shadow" *ngIf="invoiceData" id="print-section">
    <div class="invoice__top mb-2">
      <div class="parent_header">
        <div class="invoice__top-logo row" id="header">
          <div class="invoice__top-logo-image mt-3">
            <img src="../../assets/logo-square.png" height="40" alt="" />
          </div>
          <div class="invoice__top-logo-text row ml-3 align-items-center">
            METILLI
          </div>
        </div>
      </div>

      <div class="table__header-overlap"></div>
      <hr class="w-100 mt-3 mb-5 logo-hr" />
      <div class="row">
        <div class="invoice__top-left col-6 p-4">
          <div class="row">
            <p class="col-12 px-0 mb-4 font-weight-bold">Bill To:</p>
          </div>
          <div class="row">
            <p class="col-12 px-0">{{ invoiceData.customerName }}</p>
          </div>
          <div class="row">
            <p class="col-12 px-0">{{ invoiceData.address }}</p>
          </div>

          <div class="row mt-2">
            <b>{{ invoiceData.projectType }} </b>
          </div>
        </div>
        <div class="invoice__top-right col-6 d-flex flex-column justify-content-start pl-5 pr-0">
          <div class="row invoice__top-right-title mb-3 mt-2">Quote</div>

          <div class="row">
            <p class="col-6 mb-0 px-0">Date:</p>
            <p class="col-6 mb-0 px-0">
              <b> {{ date | date }} </b>
            </p>
          </div>
          <hr class="w-100 logo-hr mb-0 mt-0" />
          <div class="row invoice-bank-details">
            <p class="col-6 mb-0 px-0">Account Name</p>
            <p class="col-6 mb-0 px-0">
              <b> METILLI BLR </b>
            </p>
            <p class="col-6 mb-0 px-0">Bank</p>
            <p class="col-6 mb-0 px-0">
              <b> SBI </b>
            </p>
            <p class="col-6 mb-0 px-0">Account Number</p>
            <p class="col-6 mb-0 px-0">
              <b> 39120900993 </b>
            </p>
            <p class="col-6 mb-0 px-0">IFSC</p>
            <p class="col-6 px-0 mb-0">
              <b> SBIN009044 </b>
            </p>
            <p class="col-6 px-0 mb-0">Branch</p>
            <p class="col-6 px-0 mb-0">
              <b> ELECTRONIC CITY </b>
            </p>
            <p class="col-6 px-0 mb-0">GST NO </p>
            <p class="col-6 px-0 mb-0">
              <b> 29ABNFM2586P1Z7 </b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="invoice__table my-5">
      <table class="table">
        <thead>
          <tr class="table__header-overlap">
            <th colspan="5"></th>
          </tr>
          <tr class="tableHeader">
            <th scope="col">Sr.No</th>
            <th scope="col">Product Name</th>
            <th scope="col">Description</th>
            <th scope="col">Image</th>
            <th scope="col">QTY</th>
            <th scope="col">Price</th>
            <th scope="col">GST</th>
            <th scope="col">MRP</th>
            <th scope="col">Discount</th>
            <th scope="col">Total</th>
            <!-- <th scope="col">Final Amount</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="invoiceData.sections">
            <ng-container *ngFor="let section of invoiceData.sections; index as i">
              <tr>
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  {{ section.productName }}
                </td>
                <td>
                  {{ section.description }}
                </td>
                <td>
                  <img *ngIf="section.image" [src]="'https:' + section.image" alt="image"
                    style="width: 100px; height: 100px" />
                </td>
                <td>
                  {{ section.quantity }}
                </td>
                <td>

                  {{ section.price | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                </td>
                <td>{{ section.gst }}%</td>
                <td>

                  {{ section.mrp | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                </td>
                <td>{{ section.discount }}%</td>
                <td>
                  {{ section.total | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                </td>
                <!-- <td>
                  {{ section.finalAmount | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                </td> -->
              </tr>
            </ng-container>
          </ng-container>
          <tr class="px-0">
            <td colspan="10" class="px-0">
              <div class="row justify-content-between mt-5">
                <div class="col-5 pl-0">
                  <!-- <div class="row">
                  <p><b>Product Total</b></p>
                  &nbsp;&nbsp;
                  <p class="ml-auto">
                    {{
                      invoiceData.finalProductTotal -
                        (invoiceData.finalProductTotal *
                          invoiceData.finalProductTotalDiscount) /
                          100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                  </p>
                  &nbsp;
                  <strike *ngIf="invoiceData.finalProductTotalDiscount">
                    {{
                      invoiceData.finalProductTotal
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                  </strike>
                  &nbsp;&nbsp;
                  <small
                    class="text-success font-weight-bold"
                    *ngIf="invoiceData.finalProductTotalDiscount"
                  >
                    {{ invoiceData.finalProductTotalDiscount }} % OFF
                  </small>
                </div> -->

                  <!-- <div class="row">
                  <p><b>Hardware Total</b></p>
                  &nbsp;&nbsp;
                  <p class="ml-auto">
                    {{
                      invoiceData.finalHardwareTotal -
                        (invoiceData.finalHardwareTotal *
                          invoiceData.finalHardwareTotalDiscount) /
                          100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                  </p>
                  &nbsp;
                  <strike *ngIf="invoiceData.finalHardwareTotalDiscount">
                    {{
                      invoiceData.finalHardwareTotal
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                  </strike>
                  &nbsp;&nbsp;
                  <small
                    class="text-success small font-weight-bold"
                    *ngIf="invoiceData.finalHardwareTotalDiscount"
                  >
                    {{ invoiceData.finalHardwareTotalDiscount }} % OFF
                  </small>
                </div> -->
                </div>
                <div class="col-4 pr-0">
                  <!-- <div class="row">
                  <p>Sub Total</p>
                  &nbsp;&nbsp;
                  <p class="ml-auto">
                    {{
                      invoiceData.totalAmount -
                        (invoiceData.finalHardwareTotal *
                          invoiceData.finalHardwareTotalDiscount) /
                          100 -
                        (invoiceData.finalProductTotal *
                          invoiceData.finalProductTotalDiscount) /
                          100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                  </p>
                </div> -->
                  <!-- <div class="row">
                  <p>GST</p>
                  &nbsp;&nbsp;
                  <p class="ml-auto">{{ invoiceData.gstPercent }}%</p>
                </div> -->
                  <div class="row p-2">
                    <p class="my-0">Total Price</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{ totalPrice | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                    </p>
                  </div>
                  <div class="row p-2">
                    <p class="my-0">Total Discount</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{ totalDiscount | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                    </p>
                  </div>
                  <div class="row p-2">
                    <p class="my-0">Total GST</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{ totalGst | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                    </p>
                  </div>

                  <div class="row p-2" *ngIf="invoiceData.paidAmount">
                    <p class="my-0">Paid Amount</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{ invoiceData.paidAmount | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                    </p>
                  </div>
                  <div class="row p-2" *ngIf="invoiceData.paidAmount">
                    <p class="my-0">Remaining Amount</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{ totalAmount - invoiceData.paidAmount | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                    </p>
                  </div>
                  <div class="row bg-dark text-white p-2" *ngIf="invoiceData.totalAmount">
                    <p class="my-0">Grand Total</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{ totalAmount | currency: "INR":"symbol-narrow":"2.0":"en-IN"}}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfooter">
          <tr class="w-100">
            <td colspan="5" class="px-0 border-top-0 w-100">
              <div class="footSpace w-100"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="spacer"></div>
    <div class="lastRow">
      <div class="col-3"></div>
      <div class="justify-content-center pl-0">
        <div class="row mb-2 mt-4 justify-content-center">
          Metilli- The Luxury Living
        </div>
        <div class="row mb-2 justify-content-center">
          #5/5, 17th Cross 7th Sector, HSR layout,Bengaluru, 560102
        </div>
        <div class="row mb-2 justify-content-center">
          Ph No. +91 6361957444
        </div>
      </div>
    </div>

    <!-- <div class="lastRow">
      <table class="table">
        <tbody>
          <tr class="px-0">
            <td colspan="5" class="border-top-0 px-0">
              <div class="invoice__bottom mt-5">
                <div class="row mt-5">
                  <div class="invoice__bottom-left col-4 pl-0">
                    <div class="row mb-2 mt-4 font-weight-bold justify-content-center">
                      Padmavathi Peddibhotla
                    </div>
                    <div class="row mb-2 justify-content-center">
                      Account Manager
                    </div>
                  </div>
                  <div class="invoice__bottom-center col-8">
                    <div class="justify-content-center">
                      Metilli- The Luxury Living <br>
                      #5/5, 17th Cross 7th Sector, HSR layout,Bengaluru, 560102 <br> 
                      Ph No. +91 6361957444
                    </div>
                  </div>
                  <div class="invoice__bottom-right col-4 pr-0">
                    <ng-container *ngIf="invoiceData.designerName">
                      <div class="row mb-2 font-weight-bold justify-content-center">
                        {{ invoiceData.designerName }}
                      </div>
                    </ng-container>
                    <div class="row mb-2 justify-content-center">
                      {{ invoiceData.designerContact }}
                    </div>
                    <div class="row mb-2 justify-content-center">
                      {{ invoiceData.designerEmail }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <div class="terms">
      <div class="table__header-overlap"></div>
      <hr class="w-100 mt-3 mb-2 logo-hr" />
      <div class="row"></div>
      <div class="row justify-content-center my-3">
        <h1 class="font-weight-bold mt-3">Terms & Conditions</h1>
      </div>
      <div class="row terms-para" style="" [innerHTML]="terms">
      </div>
      <div class="row mr-4 font-weight-bold justify-content-end mt-5">
        Client Signature
      </div>
      <!-- <div style="page-break-after: always"></div> -->
      <div class="lastRow">

      </div>
    </div>
    <div class="bg-dark py-3 mt-3 footer divFooter w-100" style="page-break-after: always"></div>

  </div>
</div>

<div *ngIf="!invoiceData">
  <h2>Genrate the Invoice First</h2>
</div>