import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIn from '@angular/common/locales/en-IN';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPrintModule } from 'ngx-print';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { ErrorInterceptor } from './error-interceptor';
import { ImageMdoalComponent } from './image-mdoal/image-mdoal.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ListProjectsComponent } from './list-projects/list-projects.component';
import { HeaderComponent } from './shared/header/header.component';
import { SideMenuComponent } from './shared/side-menu/side-menu.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AddAgentModalComponent } from './add-agent-modal/add-agent-modal.component';
import { StartProjectModalComponent } from './start-project-modal/start-project-modal.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CreateTaskComponent } from './create-task/create-task.component';
import { CreatePhaseComponent } from './create-phase/create-phase.component';

registerLocaleData(localeIn);

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    HeaderComponent,
    ImageMdoalComponent,
    CreateProjectComponent,
    ListProjectsComponent,
    InvoiceComponent,
    UserManagementComponent,
    AddAgentModalComponent,
    StartProjectModalComponent,
    TaskDetailComponent,
    ChangePasswordModalComponent,
    CreateTaskComponent,
    CreatePhaseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgxPrintModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatDatepickerModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
