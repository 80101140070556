import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private products = [];

  constructor() {}

  pushProducts(product) {
    this.products.push(product);
  }

  popProducts(product) {
    this.products = this.products.filter((p) => p._id !== product._id);
  }

  checkProductCheckedStatus(product) {
    return !!this.products.find((p) => p._id === product._id);
  }

  get invoiceProducts() {
    return this.products;
  }
}
