import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  isAdmin = false;
  isInventoryManagers = false;
  isToolUser = false;
  isSalesTeam = false;
  isSaleManager = false;
  isManager = false;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const currentUser = this.authService.currentUserValue;
    if (currentUser.roles.indexOf('admin') !== -1) {
      this.isAdmin = true;
    }
    if (
      currentUser.roles.indexOf('agent') !== -1 ||
      currentUser.roles.indexOf('store-manager') !== -1
    ) {
      this.isInventoryManagers = true;
    }
    if (
      currentUser.roles.indexOf('manager') !== -1 ||
      currentUser.roles.indexOf('designer') !== -1
    ) {
      this.isToolUser = true;
    }
    if (
      currentUser.roles.indexOf('saleManager') !== -1
    ) {
      this.isSaleManager = true;
    }
    if (
      currentUser.roles.indexOf('manager') !== -1
    ) {
      this.isManager = true;
    }
  }
}
