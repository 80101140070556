import { Router } from '@angular/router';
import { AuthService } from './../../auth.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  currentUser;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }

  toggleSidebar() {
    this.toggleSidebarForMe.emit();
  }

  onLogout() {
    this.authService.logout();
    this.router.navigateByUrl('/auth/login');
  }

}
