import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  summary: boolean = false;
  applianceDiscount;
  applianceTotal;
  applianceFinalTotal = [];
  terms;
  isAdmin = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }
  showSummary() {
    this.summary = true;
  }
  showEstimate() {
    this.summary = false;
  }

  /* CODE FOR GENERATING THROUGH JSPDF(May Be Required in Future ) START HERE*/

  /* 
  convertToPdf() {
    var data = document.getElementById('print-section');
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

      //CODE FOR GENERATING MULTIPAGE PDF STARTS
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      //CODE FOR GENERATING MULTIPAGE PDF ENDS

      pdf.save(this.invoiceData.customerName + '.pdf'); // Generated PDF
    });
  }
 */

  /* CODE FOR GENERATING THROUGH JSPDF(May Be Required in Future ) START HERE*/
  invoiceData;
  totalCost = 0;
  hardwareData = [];
  sectionData = [];
  applianceData = [];

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const currentUser = this.authService.currentUserValue;
      if (currentUser.roles.indexOf('admin') !== -1) {
        this.isAdmin = true;
      }
      if (!paramMap.has('id')) {
        return;
      }
      const id = paramMap.get('id');
      this.apiService.fetchInvoice(id).subscribe((res) => {
        this.invoiceData = res.data;
        // console.log(this.invoiceData);
        /* let data = [];
        let hData = [];
        this.invoiceData.sections.forEach((x) => {
          this.sectionData.push(x);
          this.sectionData.forEach((x) => {
            this.hardwareData.push(x.hardwares);

            this.hardwareData.forEach((x) => {
              data = x;
            });
          });
        }); */
        /* 
        console.log('HIT', this.sectionData);
        console.log('HITdffd', this.hardwareData);
        console.log('sfsdf', data); */
        /* 
                let appFinalTotal = [];
                let appTotal = [];
        
                this.invoiceData.sections.forEach((element) => {
                  element.appliances.forEach((element) => {
                    //console.log('DATA', element);
                    appFinalTotal.push(
                      element.total - (element.total * element.discount) / 100
                    );
                    appTotal.push(
                      element.total
                    );
                    this.applianceFinalTotal = appFinalTotal.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                    this.applianceTotal = appTotal.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                    console.log('FFFFFFF', this.applianceFinalTotal);
                    console.log('FFFFFFF', this.applianceTotal);
                  });
                }); */
        //console.log(this.totalCost);
      });
      this.apiService.getTerms('project').subscribe((res) => {
        if (res && res.data && Array.isArray(res.data) && res.data[0] && res.data[0].value) {
          this.terms = res.data[0].value;
        }
      });
    });
  }
}
