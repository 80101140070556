import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';

import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-generate-modal',
  templateUrl: './generate-modal.component.html',
  styleUrls: ['./generate-modal.component.scss'],
})
export class GenerateModalComponent implements OnInit {
  generateForm: FormGroup;
  customers = [];
  products = [];
  paidAmount = 0;

  constructor(
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loadForm();
    this.fetchCustomers();
  }

  ngOnInit(): void {
    const data = this.apiService.getSampleInvoiceData();
    if (data) {
      this.generateForm.patchValue({
        customerAddress: data.customerAddress,
        customerID: data.customerID,
        customerName: data.customerName,
        paidAmount: data.paidAmount,
        totalAmount: data.totalAmount
      });
      this.paidAmount = data.paidAmount;
      const prodArray = this.generateForm.get('products') as FormArray;
      data.products.forEach((product) => {
        prodArray.push(this.prodFormArray(product));
      });
    } else {
      this.fetchProducts({
        pageSize: 10,
        pageNo: 0,
      });

      const prodArray = this.generateForm.get('products') as FormArray;

      this.productService.invoiceProducts.forEach((product) => {
        prodArray.push(this.prodFormArray(product));
      });

      this.calculateOrderTotal();
    }
  }

  ngAfterViewInit() {
    this.generateForm
      .get('searchControl')
      .valueChanges.pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((val) => {
        if (!val) {
          this.fetchProducts({
            pageSize: 10,
            pageNo: 0,
          });
          return;
        }
        this.fetchProducts({ search: val });
      });
  }

  loadForm() {
    this.generateForm = new FormGroup({
      customerID: new FormControl(''),
      customerName: new FormControl('', Validators.required),
      customerAddress: new FormControl('', Validators.required),
      searchControl: new FormControl(''),
      totalAmount: new FormControl('', Validators.required),
      products: new FormArray([], Validators.required),
      paidAmount: new FormControl(),
    });
  }

  prodFormArray(product): FormGroup {
    return new FormGroup({
      productID: new FormControl(product._id ? product._id : product.productID, Validators.required),
      name: new FormControl(product.name, Validators.required),
      image: new FormControl(product.image),
      mrp: new FormControl(product.mrp, Validators.required),
      price: new FormControl(product.price, Validators.required),
      costPrice: new FormControl(product.costPrice, Validators.required),
      quantity: new FormControl(
        product.quantity ? product.quantity : 1,
        Validators.required
      ),
      discount: new FormControl(product.discount ? product.discount : 0),
      description: new FormControl(product.description.external ? product.description.external : product.description),
      totalMrp: new FormControl(product.totalMrp ? product.totalMrp : product.mrp, Validators.required),
      totalCostPrice: new FormControl(product.costPrice, Validators.required),
      costFactor: new FormControl(product.costFactor, Validators.required),
      currency: new FormControl(product.currency),
      currencyConversionToInr: new FormControl(product.currencyConversionToInr),
    });
  }

  onSelectCustomer(event) {
    console.log('onSelect=', event.value);
    console.log('onSelectOption=', event.option.value);
    if (event && event.option.value) {
      const customer = this.customers.find((c) => c._id === event.option.value);
      this.generateForm.patchValue({
        customerName: customer.firstName + ' ' + customer.lastName,
        customerID: event.option.value,
      });
      // this.customerAddresses = customer.address;
      // console.log(this.customerAddresses);
      // this.addAddress()
      this.onSelectAddress(customer.address[0]);
      console.log('address', customer.address);
    }
    // if (event && event.value) {
    //   this.generateForm.patchValue({
    //     customerName: event.value,
    //   });
    // }
  }

  onSelectAddress(address) {
    // if (event && event.value) {
    // const address = event.value;
    const projAddress =
      address.building +
      (address.street ? ', ' + address.street : '') +
      (address.locality ? ', ' + address.locality : '') +
      (address.landmark ? ', ' + address.landmark : '') +
      ', PIN: ' +
      address.pin;
    this.generateForm.patchValue({
      customerAddress: projAddress,
    });
    // }
  }

  onGenerate() {
    if (!this.generateForm.valid) {
      this.matSnackBar.open('Please fill all required fields', 'OK', {
        duration: 3000,
      });
      return;
    }
    if (this.generateForm.value) {
      // localStorage.setItem(
      //   'sampleInvoice',
      //   JSON.stringify(this.generateForm.value)
      // );
      this.apiService.setSampleInvoiceData(this.generateForm.value);

      this.router.navigate(['/salesInvoice']);
    }
    console.log(this.generateForm.value);
  }

  fetchCustomers() {
    this.apiService.listCustomers({}).subscribe((res) => {
      if (res && res.data) {
        this.customers = res.data;
      } else {
        this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
          duration: 3000,
        });
      }
    });
  }

  fetchProducts(data) {
    this.apiService.listInventoryProducts(data).subscribe((res) => {
      if (res && res.data) {
        this.products = res.data;
      }
    });
  }

  displayFn(product): string {
    if (product === 'newProd') {
      return;
    }
    return '';
  }

  displayCustomer(customer) {
    // if (product === 'newProd') {
    //   return;
    // }
    return customer;
  }

  onSelectProduct(event) {
    if (event && event.option && event.option.value) {
      const product = event.option.value;
      if (product === 'newProd') {
        return;
      }

      if (this.checkIdProdAlreadyExists(product)) {
        this.matSnackBar.open('Product already exists !', 'OK', {
          duration: 3000,
        });
        return;
      }
      product.price = product.mrp / 1.18;
      const prodArray = this.generateForm.get('products') as FormArray;
      prodArray.push(this.prodFormArray(product));
      this.calculateOrderTotal();
    }
  }

  applyGlobalDiscount(event) {
    const discountValue = event.target.value;

    const prodArray = this.generateForm.get('products') as FormArray;
    prodArray.value.forEach((_, index) => {
      const discount = this.generateForm.get([
        'products',
        index,
        'discount',
      ]) as FormControl;
      discount.setValue(discountValue);

      this.onCalculateTotals(index);
    });
  }

  applyPaidAmount(event) {
    this.paidAmount = event.target.value;
    this.generateForm.get('paidAmount').setValue(parseInt(this.paidAmount.toString()));
    this.calculateOrderTotal();
  }

  calculateOrderTotal() {
    const totalAmount = this.generateForm
      .get('products')
      .value.reduce((acc, curr) => acc + +curr.totalMrp, 0);

    this.generateForm.get('totalAmount').setValue(totalAmount);
  }

  onCalculateTotals(index) {
    const quantity = this.generateForm.get([
      'products',
      index,
      'quantity',
    ]).value;
    const discount = this.generateForm.get([
      'products',
      index,
      'discount',
    ]).value;
    const price = this.generateForm.get(['products', index, 'price']).value;
    const costPrice = this.generateForm.get([
      'products',
      index,
      'costPrice',
    ]).value;

    this.generateForm
      .get(['products', index, 'totalCostPrice'])
      .setValue(costPrice * quantity);
    const total = price * quantity - price * quantity * (discount / 100);
    this.generateForm
      .get(['products', index, 'totalMrp'])
      .setValue(total + (18 * (total / 100)));

    this.calculateOrderTotal();
  }

  checkIdProdAlreadyExists(product): boolean {
    let isThere = false;
    const prodArray = this.generateForm.get('products').value;
    const prod = prodArray.find((el) => el.productID === product._id);

    if (prod) isThere = true;

    return isThere;
  }

  onRemove(id) {
    const prodArray = this.generateForm.get('products') as FormArray;
    prodArray.removeAt(prodArray.value.findIndex((p) => p.productID === id));
  }
}
