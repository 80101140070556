<div class="modal_parent">
    <div class="sameline_horizontal">
        <div>
            <h2>
                <strong>Task Details</strong>
            </h2>
        </div>
        <div>
            <mat-icon style="cursor: pointer" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div class="modal_content" mat-dialog-content>
        <div>
            <form [formGroup]="taskForm">
                <div class="row">
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Task Name</mat-label>
                            <input type="text" (keyup)="onChangeTaskName($event.target)" placeholder="Enter Task"
                                matInput formControlName="taskName" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Timeline</mat-label>
                            <input matInput placeholder="Enter Timeline" type="text" formControlName="timeline"
                                (keyup)="onChangeTimeline($event.target)" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Priority</mat-label>
                            <mat-select (selectionChange)="onSelectPriority($event)" placeholder="Select Priority"
                                formControlName="priority">
                                <mat-option value="high">High</mat-option>
                                <mat-option value="medium">Medium</mat-option>
                                <mat-option value="low">Low</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Description</mat-label>
                            <input type="text" placeholder="Enter description" matInput formControlName="description"
                                (keyup)="onChangeDescription($event.target)" required />
                        </mat-form-field>
                    </div>
                </div>
                <mat-accordion>
                    <mat-expansion-panel class="mx-1 my-2 rounded">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Pre Checklist

                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="productTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Name</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr formArrayName="preCheckList" *ngFor="
                      let pRow of taskForm.get('preCheckList')['controls'];
                      let i = index
                    ">
                                        <td>{{ i + 1 }}</td>
                                        <td [formGroupName]="i">
                                            <input type="text" (keyup)="preCheckListUpdate(pRow.value, i)"
                                                formControlName="name" required />
                                        </td>
                                        <td [formGroupName]="i" (click)="preCheckListDelete(pRow.value, i)">
                                            <mat-icon>delete</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn">
                            <button type="button" (click)="addPreCheckList()">
                                Add
                            </button>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mx-1 my-2 rounded">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Post Checklist

                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="productTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Name</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr formArrayName="postCheckList" *ngFor="
                      let pRow of taskForm.get('postCheckList')['controls'];
                      let i = index
                    ">
                                        <td>{{ i + 1 }}</td>
                                        <td [formGroupName]="i">
                                            <input type="text" (keyup)="postCheckListUpdate(pRow.value, i)"
                                                formControlName="name" required />
                                        </td>
                                        <td [formGroupName]="i" (click)="postCheckListDelete(pRow.value, i)">
                                            <mat-icon>delete</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="btn">
                            <button type="button" (click)="addPostCheckList()">
                                Add
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div class="row">
                    <div class="col-md">
                        <ng-container *ngIf="this.taskForm.get('subTask').value.length > 0">
                            <div class="pt-4">
                                <h2>
                                    <strong>Sub Tasks</strong>
                                </h2>
                            </div>
                            <hr />
                            <div class="productTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S. No.</th>
                                            <th>Name</th>
                                            <th>Timeline</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formArrayName="subTask" class="pt-1" *ngFor="
                          let pRow of taskForm.get('subTask')['controls'];
                          let i = index
                        ">
                                            <td>{{ i + 1 }}</td>
                                            <td [formGroupName]="i">
                                                <input type="text" (keyup)="subtaskUpdate(pRow.value, i)"
                                                    formControlName="name" required />
                                            </td>
                                            <td [formGroupName]="i">
                                                <input type="text" (keyup)="subtaskUpdate(pRow.value, i)"
                                                    formControlName="timeline" required />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                        </ng-container>
                        <div class="btn">
                            <button type="button" (click)="addSubTask()">
                                Add Subtask
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>