<mat-toolbar>
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSidebar()" >
            <mat-icon>menu</mat-icon>
        </button><!-- 
        <span>App Logo</span> -->
        <div class="row mr-2 ml-auto" >
            <ul class="row m-0 align-items-center">
                <!-- <li>
                    <button mat-icon-button>
                        <mat-icon>settings</mat-icon>
                    </button>
                </li> -->
                <li>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <img src="../../../assets/logo.png" alt="" class="user-image mr-1 p-2">
                        {{currentUser?.firstName}}
                        <mat-icon class=user-image-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onLogout()">
                            <mat-icon>exit_to_app</mat-icon>
                            Logout
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>