<div class="modal_parent">
    <div class="sameline_horizontal">
        <div>
            <h2>
                <strong>Add Task</strong>
            </h2>
        </div>
        <div>
            <mat-icon style="cursor: pointer" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div class="modal_content" mat-dialog-content>
        <div>
            <form [formGroup]="taskForm" (ngSubmit)="onAddTask()">
                <div class="row">
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Phase</mat-label>
                            <mat-select placeholder="Select Phase" formControlName="phase">
                                <mat-option *ngFor="let p of phases" [value]="p._id">{{
                                    p.name |titlecase
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col -md">
                        <mat-form-field>
                            <mat-label>Task Name</mat-label>
                            <input matInput placeholder="TaskName" type="text" formControlName="name" required />
                        </mat-form-field>
                    </div>
                    <div class="col -md">
                        <mat-form-field>
                            <mat-label>Timeline</mat-label>
                            <input matInput placeholder="Timeline" type="text" formControlName="timeline" required />
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Priority</mat-label>
                            <mat-select placeholder="Select Priority" formControlName="priority" required>
                                <mat-option value="high">High</mat-option>
                                <mat-option value="medium">Medium</mat-option>
                                <mat-option value="low">Low</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Description</mat-label>
                            <input type="text" placeholder="Enter description" matInput formControlName="description"
                                required />
                        </mat-form-field>
                    </div>
                </div>
                <div align="center">
                    <button class="btn_common" type="submit">Add</button>
                </div>
            </form>
        </div>
    </div>
</div>