import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('mUser'))
    );
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  // login
  login(data) {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, data).pipe(
      map((user) => {
        if (user && user.Authorization && user.roles.indexOf('user') === -1) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let objectToSave = {
            ...user,
            timeStamp: new Date(),
          };
          localStorage.setItem('mUser', JSON.stringify(objectToSave));
          this.currentUserSubject.next(objectToSave);
        } else {
          this._snackBar.open(
            'Only Admin, Managers and Designers can login !',
            'OK',
            { duration: 3000 }
          );
        }
        return user;
      })
    );
  }

  // signup
  signup(data) {
    return this.http.post<any>(`${environment.apiUrl}/auth/signup`, data).pipe(
      map((user) => {
        if (user && user.Authorization && user.roles.indexOf('user') === -1) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let objectToSave = {
            ...user,
            timeStamp: new Date(),
          };
          localStorage.setItem('mUser', JSON.stringify(objectToSave));
          this.currentUserSubject.next(objectToSave);
        } else {
          this._snackBar.open(
            'Successfully Registered but Only Admins, Managers and Designers can login !',
            'OK',
            { duration: 3000 }
          );
        }
        return user;
      })
    );
  }

  createUser(data) {
    return this.http.post<any>(`${environment.apiUrl}/auth/signup`, data);
  }

  // logout
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('mUser');
    this.currentUserSubject.next(null);
  }
}
