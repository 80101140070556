<div class="modal_parent">
  <div class="sameline_horizontal">
    <div>
      <h2>
        <strong>Add Agent</strong>
      </h2>
    </div>
    <div>
      <mat-icon style="cursor: pointer" [mat-dialog-close]="true"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="modal_content" mat-dialog-content>
    <div>
      <form [formGroup]="agentForm" (ngSubmit)="onAddAgent()">
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input
                matInput
                placeholder="Name"
                type="text"
                formControlName="firstName"
                required
              />
            </mat-form-field>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input
                matInput
                placeholder="Name"
                type="text"
                formControlName="lastName"
                required
              />
            </mat-form-field>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <mat-form-field>
              <mat-label>Phone Number</mat-label>
              <input
                matInput
                placeholder="Phone Number"
                type="number"
                formControlName="phone"
              />
            </mat-form-field>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <mat-form-field>
              <mat-label>Email Address</mat-label>
              <input
                matInput
                placeholder="Email Address"
                formControlName="email"
                required
              />
            </mat-form-field>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                matInput
                placeholder="Password"
                type="password"
                formControlName="password"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row" formGroupName="agentDefaults">
          <div class="col">
            <mat-form-field>
              <mat-label>Currency</mat-label>
              <mat-select
                placeholder="Select Currency"
                formControlName="currency"
              >
                <mat-option value="inr">INR</mat-option>
                <mat-option value="usd">USD</mat-option>
                <mat-option value="rub">RUB</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <mat-form-field>
              <mat-label>Currenct Conversion Factor</mat-label>
              <input
                matInput
                placeholder="Currenct Conversion Factor"
                type="number"
                formControlName="currencyConversionToInr"
              />
            </mat-form-field>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <mat-form-field>
              <mat-label>Cost Factor</mat-label>
              <input
                matInput
                placeholder="Cost Factor"
                type="number"
                formControlName="costFactor"
              />
            </mat-form-field>
          </div>
        </div>
        <div align="center">
          <button class="btn_common" type="submit">Add</button>
        </div>
      </form>
    </div>
  </div>
</div>
