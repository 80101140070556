import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit {
  taskForm: FormGroup;
  phases = [];
  phaseId;
  projectId
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CreateTaskComponent>
  ) {
    this.loadTaskForm();
    this.route.queryParamMap.subscribe((pMap) => {
      this.phaseId = pMap.get("phase");
      this.projectId = pMap.get("projectId");
    });
  }

  ngOnInit(): void {
    if (this.data.isDefault) {
      this.apiService.getDefaultPhases().subscribe((response) => {
        this.phases = response.data;
        if (this.phaseId) {
          const a = (this.phases.filter(a => a._id === this.phaseId))[0]._id;
          this.taskForm.get("phase").setValue(a);
        }
      });
    } else {
      this.apiService.fetchStartedProjectData(this.projectId).subscribe((response) => {
        console.log(response);
        this.phases = response.data[0].phase;
      });
      // this.apiService.post().subscribe((response) => {
      //   this.phases = response.data;
      //   if (this.phaseId) {
      //     const a = (this.phases.filter(a => a._id === this.phaseId))[0]._id;
      //     this.taskForm.get("phase").setValue(a);
      //   }
      // });
    }
  }

  loadTaskForm() {
    this.taskForm = new FormGroup({
      name: new FormControl('', Validators.required),
      timeline: new FormControl('', Validators.required),
      phase: new FormControl('', Validators.required),
      priority: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    })
  }

  onAddTask() {
    if (this.taskForm.valid) {
      if (this.data.isDefault) {
        this.apiService.addDefaultTask(this.taskForm.value).subscribe((res) => {
          if (res) {
            this.matSnackBar.open('Task added successfully !', 'OK', {
              duration: 3000,
            });
            this.dialogRef.close({ agentProp: true });
          }
        });
      } else {
        this.taskForm.value._postProjectId = this.projectId;
        this.apiService.addTask(this.taskForm.value).subscribe((res) => {
          if (res) {
            this.matSnackBar.open('Task added successfully !', 'OK', {
              duration: 3000,
            });
            this.dialogRef.close({ agentProp: true });
          }
        });
      }
    }
  }
}
