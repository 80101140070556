<div class="modal_parent">
    <div class="sameline_horizontal">
        <div>
            <h2>
                <strong>Add Phase</strong>
            </h2>
        </div>
        <div>
            <mat-icon style="cursor: pointer" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div class="modal_content" mat-dialog-content>
        <div>
            <form [formGroup]="phaseForm" (ngSubmit)="onAddPhase()">
                <div class="row">
                    <div class="col -md">
                        <mat-form-field>
                            <mat-label>Phase Name</mat-label>
                            <input matInput placeholder="PhaseName" type="text" formControlName="name" required />
                        </mat-form-field>
                    </div>
                    <div class="col -md">
                        <mat-form-field>
                            <mat-label>Timeline</mat-label>
                            <input matInput placeholder="Timeline" type="text" formControlName="timeline" required />
                        </mat-form-field>
                    </div>
                </div>
                <div align="center">
                    <button class="btn_common" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>