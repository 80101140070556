<div class="modal_parent">
    <div class="sameline_horizontal">
        <div>
            <h2>
                <strong>Start Project</strong>
            </h2>
        </div>
        <div>
            <mat-icon style="cursor: pointer" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div class="modal_content" mat-dialog-content>
        <div>
            <form [formGroup]="projectStartForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Project Type</mat-label>
                            <mat-select placeholder="Select Project Type" formControlName="projectType">
                                <mat-option value="inHouse">In-house</mat-option>
                                <mat-option value="imported">Imported</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="w-100"></div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Select Project Manager</mat-label>
                            <input type="text" placeholder="Enter Product" matInput formControlName="searchControl"
                                [matAutocomplete]="search" />
                            <mat-autocomplete #search="matAutocomplete" (optionSelected)="onSelectUser($event)"
                            [displayWith]="displayFn">
                                <hr style="margin: 4px" />
                                <mat-option *ngFor="let user of users" [value]="user">
                                    {{ user.firstName }} {{ user.lastName }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="w-100"></div>
                    <!-- <div class="row">
                        <div class="col-md">
                            
                        </div>
                    </div> -->
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Project Name</mat-label>
                            <input matInput placeholder="Project Name" type="text" formControlName="projectName"
                                required />
                        </mat-form-field>
                    </div>
                    <div class="w-100"></div>
                    <div class="col">
                        <table>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Phase</th>
                                    <th>Timeline(Days)</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="phases">
                                <tr *ngFor="
                                  let phase of phases;
                                  let i = index;
                                ">
                                    <td>
                                        <mat-checkbox color="primary" (change)="onSelectPhase($event, phase)"
                                            [checked]="isPhaseChecked(phase)">
                                        </mat-checkbox>
                                    </td>
                                    <td>{{ phase.name | titlecase}}</td>
                                    <td>
                                        <mat-form-field>
                                            <input matInput id="phases[i].timeline" name="phases[i].timeline"
                                                type="text" [ngModelOptions]="{standalone: true}" required
                                                [(ngModel)]="phases[i].timeline" />
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="w-100"></div>

                </div>
                <div align="center">
                    <button class="btn_common" type="submit">Confirm</button>
                </div>
            </form>
        </div>
    </div>
</div>