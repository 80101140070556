import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  passwordForm: FormGroup;
  passwordMatch = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public email: any,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ChangePasswordModalComponent>
    ) { }

  ngOnInit(): void {
    this.loadPasswordForm();
  }

  loadPasswordForm() {
    this.passwordForm = new FormGroup({
      password: new FormControl('', Validators.required),
      cnfPassword: new FormControl('', Validators.required)
    });
  }
  onSubmit() {
    if (this.passwordForm.valid && this.email && this.passwordForm.get('password').value) {
      const data = {
        email: this.email,
        password: this.passwordForm.get('password').value
      };
      this.apiService.updatePassword(data).subscribe((res) => {
        if (res && res.data) {
          this.matSnackBar.open('Password updated successfully!', 'OK', {
            duration: 3000,
          });
          this.dialogRef.close({ agentProp: true });
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      });
    } else if (!this.passwordMatch) {
      this.matSnackBar.open('Password does not match', 'OK', {
        duration: 3000,
      });
    }
  }

  passwordChecker = () => {
    const password = this.passwordForm.get('password').value;
    const confirm = this.passwordForm.get('cnfPassword').value;
    if (password !== confirm) {
      this.passwordForm.controls.cnfPassword.setErrors({ passwordMatch: true });
      this.passwordMatch = false;
    } else {
      // this.passwordForm.controls.cnfPassword.setErrors({ passwordMatch: false });
      this.passwordMatch = true;
    }
  }
}
