<div>
    <div class="sameline_horizontal">
        <div>
            <h2><strong>Image</strong> </h2>
        </div>
        <div>
            <mat-icon style="cursor: pointer;" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div mat-dialog-content style="max-width: 40vw; max-height: 40vh;">
        <img [src]="'https:'+data" style="width: 100%;">
    </div>
</div>