<!-- <router-outlet></router-outlet>
 -->


<mat-drawer-container>
    <mat-drawer *ngIf="showSidemenuAndHeader" mode="side" [opened]="sideBarOpen">
        <app-side-menu></app-side-menu>
    </mat-drawer>
    <mat-drawer-content>
        <app-header *ngIf="showSidemenuAndHeader" (toggleSidebarForMe)="sideBarToggler()"></app-header>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>