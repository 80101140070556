import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { StartProjectModalComponent } from '../start-project-modal/start-project-modal.component';
import { AuthService } from '../auth.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.scss'],
})
export class ListProjectsComponent implements OnInit {
  searchControl: FormControl = new FormControl('');
  projects = [];
  isLoading = false;

  // pagination vars
  pageNum: number = 1;
  perPage: number = 10;
  pageSizeOptions = [10, 20, 50, 100];
  totalProjects: number;

  // Page output event
  pageEvent: PageEvent;

  queryData: any = {};

  customerName: string;
  isAdmin = false;

  constructor(
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((pMap) => {
      if (!pMap.has('customer')) {
        this.fetchProjects({});
        return;
      }

      this.customerName = pMap.get('customer');
      this.fetchProjects({ customerName: this.customerName });
    });
    const currentUser = this.authService.currentUserValue;
    if (currentUser.roles.indexOf('admin') !== -1) {
      this.isAdmin = true;
    }

    // Handle search input with debounce and distinctUntilChanged
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged() // Only trigger when search text actually changes
      )
      .subscribe((searchText) => {
        this.pageNum = 1; // Reset pagination on new search
        this.fetchProjects({ searchText });
      });
  }

  onCloneProject(project) {
    const p = { ...project };
    delete p._id;
    delete p.created;
    delete p.updated;
    this.apiService.createProject(p).subscribe((res) => {
      if (res && res.data) {
        this.matSnackBar.open('Project Cloned Successfully !', 'OK', {
          duration: 3000,
        });
        this.fetchProjects({});
      } else {
        this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
          duration: 3000,
        });
      }
    });
  }

  onRemoveQuote(id) {
    this.apiService
      .updateProject(id, { status: 'archive' })
      .subscribe((res) => {
        if (res && res.data) {
          this.projects = this.projects.filter((p) => p._id !== id);
          this.matSnackBar.open('Quote Removed Successfully !', 'OK', {
            duration: 3000,
          });
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      });
  }

  onPageChange(event: PageEvent) {
    this.perPage = event.pageSize;
    this.pageNum = event.pageIndex + 1;

    if (this.customerName) {
      this.fetchProjects({ customerName: this.customerName });
    } else {
      this.fetchProjects({});
    }
  }

  fetchProjects(data) {
    this.queryData = {
      ...data,
      perPage: this.perPage,
      pageNum: this.pageNum,
    };


    const searchText = this.searchControl.value?.trim();
    if (searchText) {
      this.queryData.searchText = searchText;
    }

    this.isLoading = true;

    this.apiService.listProjects(this.queryData).subscribe((res) => {
      if (res && res.data) {
        this.projects = res.data;
        this.totalProjects = res.count;
      } else {
        this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
          duration: 3000,
        });
      }
      this.isLoading = false;
    });
  }

  onStartProject(customerName, _id) {
    const dialogRef = this.dialog.open(StartProjectModalComponent, {
      data: { customerName, _id },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (this.customerName) {
        this.fetchProjects({ customerName: this.customerName });
      } else {
        this.fetchProjects({});
      }
    });
  }
}
