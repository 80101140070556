import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { AddAgentModalComponent } from '../add-agent-modal/add-agent-modal.component';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  searchText: string;
  users = [];
  filterValue = 'all';
  text = 'all'
  constructor(
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchUsers({});
  }

  filterUsersOnRole(event) {
    if (event && event.value) {
      if (event.value === 'all') {
        this.fetchUsers({});
        return;
      }
      const data = {
        roles: [event.value],
      };
      this.fetchUsers(data);
    }
  }

  onChangeUserRole(event, id) {
    if (event && event.value) {
      const data = {
        userID: id,
        roles: [event.value],
      };
      this.apiService.updateUserRole(data).subscribe((res) => {
        if (res && res.data) {
          this.matSnackBar.open('Role updated successfully!', 'OK', {
            duration: 3000,
          });
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
      });
    }
  }

  onDeleteUser(event, id) {
    if (event && event.value) {
      const data = {
        userID: id,
        roles: [event.value],
      };
      this.apiService.updateUserRole(data).subscribe((res) => {
        if (res && res.data) {
          this.matSnackBar.open('User deleted successfully!', 'OK', {
            duration: 3000,
          });
        } else {
          this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
            duration: 3000,
          });
        }
        this.text = 'all'
        this.fetchUsers({});
      });
    }
  }

  onAddAgent() {
    const dialogRef = this.dialog.open(AddAgentModalComponent);

    dialogRef.afterClosed().subscribe((res) => {
      if (res['agentProp']) {
        this.fetchUsers({});
      }
    });
  }

  fetchUsers(data) {
    this.apiService.listUserByRole(data).subscribe((res) => {
      if (res && res.data) {
        this.users = res.data;
      } else {
        this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
          duration: 3000,
        });
      }
    });
  }

  onPasswordChange(email, password) {
    const dialogRef = this.dialog.open(ChangePasswordModalComponent, {
      data: email
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.fetchUsers({});
    });
    
  }
}
