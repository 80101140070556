import { AuthService } from './auth.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Metilli';
  sideBarOpen = true;
  showSidemenuAndHeader: boolean;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkForTokenOnPageLoad();
        if (window.location.href.includes('login')) {
          this.showSidemenuAndHeader = false;
        } else if (window.location.href.includes('signup')) {
          this.showSidemenuAndHeader = false;
        }
        else if (window.location.href.includes('not-found')) {
          this.showSidemenuAndHeader = false;
        }
        else {
          this.showSidemenuAndHeader = true;
        }
      }
    })
  }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  checkForTokenOnPageLoad() {
    let user = JSON.parse(localStorage.getItem('mUser'));
    if (user !== null) {
      if (user.Authorization && user.timeStamp) {
        if (moment(new Date()).diff(user.timeStamp, 'hours') > 24) {
          if (!window.location.href.includes('signup')) {
            this.authService.logout();
            this.router.navigateByUrl('/auth/login');
          }
        } else {
          if (window.location.href.includes('login') || window.location.href.includes('signup')) {
            if (user.roles.indexOf('agent') !== -1) {
              this.router.navigateByUrl('/inventory');
            }else{
              this.router.navigateByUrl('/customer');
            }
          }
        }
      } else {
        if (!window.location.href.includes('signup')) {
          this.authService.logout();
          this.router.navigateByUrl('/auth/login');
        }
      }
    } else {
      if (!window.location.href.includes('signup')) {
        this.authService.logout();
        this.router.navigateByUrl('/auth/login');
      }
    }
  }
}
