<div class="parent">
  <div class="back">
    <div class="label"><mat-icon>domain</mat-icon> Projects</div>
    <div class="search">
      <input type="text" placeholder="Search Projects" [formControl]="searchControl" />
    </div>
    <div class="btn">
      <button mat-raised-button routerLink="/create-project">
        Create Project
      </button>
    </div>
  </div>
  <div align="center" *ngIf="projects.length === 0 && !isLoading" class="mt-2">
    <h1>
      No Projects... You can create one from
      <a routerLink="/create-project">here</a> !
    </h1>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="content" *ngIf="projects.length > 0">
      <table>
        <thead>
          <tr>
            <th>Customer</th>
            <th>Contact</th>
            <th>Designer</th>
            <th>House Type</th>
            <th>Total</th>
            <th>Date</th>
            <th>Invoice</th>
            <th>Clone</th>
            <th>Edit</th>
            <th *ngIf="isAdmin">Status</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of projects | filter: searchText; let i = index">
            <td>{{ p.customerName }}</td>
            <td>{{ p.phone }}</td>
            <td>{{ p.designerName }}</td>
            <td>{{ p.projectType }}</td>
            <td>
              {{
              ((p.totalAmount -
              (p.finalHardwareTotal * p.finalHardwareTotalDiscount) / 100 -
              (p.finalProductTotal * p.finalProductTotalDiscount) / 100) *
              p.gstPercent) /
              100 +
              (p.totalAmount -
              (p.finalHardwareTotal * p.finalHardwareTotalDiscount) /
              100 -
              (p.finalProductTotal * p.finalProductTotalDiscount) / 100)
              | currency: "INR":"symbol-narrow":"2.0":"en-IN"
              }}
            </td>
            <td>{{ p.created | date: "mediumDate" }}</td>
            <td>
              <mat-icon [routerLink]="['/invoice/', p._id]">assignment</mat-icon>
            </td>
            <td>
              <mat-icon (click)="onCloneProject(p)">control_point_duplicate</mat-icon>
            </td>
            <td>
              <mat-icon [routerLink]="['/edit-project/', p._id]">edit</mat-icon>
            </td>
            <td *ngIf="isAdmin">
              <mat-icon *ngIf="!p.isProjectStarted" (click)="onStartProject(p.customerName, p._id)">start</mat-icon>
              <span style="color: #008000;" *ngIf="p.isProjectStarted">Started</span>
            </td>
            <td><mat-icon (click)="onRemoveQuote(p._id)">delete</mat-icon></td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <div *ngIf="isLoading" class="justify-content-center align-items-center spinner">
    <mat-spinner></mat-spinner>
  </div>
  <mat-paginator *ngIf="projects.length > 0" [length]="totalProjects" [pageSize]="perPage"
    [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)" showFirstLastButtons aria-label="Select page">
  </mat-paginator>
</div>