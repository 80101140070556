<mat-nav-list>
  <div class="profile-card py-4">
    <img src="../../../assets/logo.png" alt="Company Logo" />
  </div>
  <mat-divider></mat-divider>
  <h2 matSubheader>APPLICATIONS</h2>

  <a
    *ngIf="isAdmin"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/analytics"
  >
    <mat-icon>assignment</mat-icon> Analytics
  </a>
  <a
    *ngIf="isAdmin || isToolUser || isSaleManager || isInventoryManagers"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/customer"
  >
    <mat-icon>people_outline</mat-icon> Customers
  </a>
  <a
    *ngIf="isAdmin || isInventoryManagers || isSaleManager"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/inventory"
  >
    <mat-icon>inventory</mat-icon> Inventory
  </a>
  <a
    *ngIf="isAdmin || isInventoryManagers"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/inventory-orders"
  >
    <mat-icon>add_shopping_cart</mat-icon> Inventory Orders
  </a>
  <a
    *ngIf="isAdmin || isToolUser"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/product-management"
  >
    <mat-icon>construction</mat-icon> Product Management
  </a>
  <a
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/dashboard"
  >
    <mat-icon>domain</mat-icon> Dashboard
  </a>
  <a
    *ngIf="isAdmin || isToolUser"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/create-project"
  >
    <mat-icon>create</mat-icon> New Project
  </a>
  <a
    *ngIf="isAdmin || isToolUser"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/list-projects"
  >
    <mat-icon>domain</mat-icon> Projects
  </a>
  <a
    *ngIf="isManager || isAdmin"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/project-management"
  >
    <mat-icon>domain</mat-icon> Project Management
  </a>
  <a
    *ngIf="isAdmin"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/user-management"
  >
    <mat-icon>account_circle</mat-icon> User Management
  </a>
  <a
    *ngIf="isAdmin"
    mat-list-item
    routerLinkActive="list-item-active"
    routerLink="/settings"
  >
    <mat-icon>construction</mat-icon> Settings
  </a>
</mat-nav-list>
