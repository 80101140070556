import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-start-project-modal',
  templateUrl: './start-project-modal.component.html',
  styleUrls: ['./start-project-modal.component.scss']
})
export class StartProjectModalComponent implements OnInit, AfterViewInit {
  projectStartForm: FormGroup;
  phases;
  checkedPhases = [];
  users = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<StartProjectModalComponent>,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.fetchUsers({});
    this.loadProjectStartForm();
    this.apiService.getDefaultPhases().subscribe((response) => {
      this.phases = response.data;
    });
  }

  ngAfterViewInit() {
    this.projectStartForm
      .get('searchControl')
      .valueChanges.pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((val) => {
        if (!val) {
          this.fetchUsers({});
          return;
        }
        this.fetchUsers({ search: val });
      });
  }

  loadProjectStartForm() {
    this.projectStartForm = new FormGroup({
      projectType: new FormControl('', Validators.required),
      // projectManager: new FormControl('', Validators.required),
      projectName: new FormControl(this.data.customerName ? this.data.customerName : '', Validators.required),
      searchControl: new FormControl('', Validators.required),
      // phase: new FormControl('', Validators.required),
      // timeline: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    if (this.projectStartForm.valid) {
      const data = this.projectStartForm.value;
      let body = {
        _projectId: this.data._id,
        phase: this.checkedPhases,
        projectType: data.projectType,
        projectName: data.projectName,
        projectManager: data.searchControl._id
      }
      this.apiService.startProject(body).subscribe((res) => {
        this.matSnackBar.open('Project started successfully', 'OK', {
          duration: 3000,
        });
        this.apiService.updateProject(this.data._id, { isProjectStarted: true }).subscribe((res) => {
          this.dialogRef.close();
        });
      });
    }
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  onSelectPhase(event: MatCheckboxChange, phase) {
    const { checked } = event;
    if (checked) this.checkedPhases.push(phase);
    else this.checkedPhases = this.checkedPhases.filter((p) => p._id !== phase._id);
  }

  isPhaseChecked(phase) {
    return !!this.checkedPhases.find((p) => p._id === phase._id);
  }

  fetchUsers(data) {
    this.apiService.listUserByRole(data).subscribe((res) => {
      if (res && res.data) {
        this.users = res.data;
      } else {
        this.matSnackBar.open('Some Problem Occurred.. Try Again !', 'OK', {
          duration: 3000,
        });
      }
    });
  }

  onSelectUser(event) {
    if (event && event.option && event.option.value) {
      const user = event.option.value;
    }
  }

  displayFn(user) {
    if (user) return user.firstName + " " + user.lastName;
    return '';

  }
}

