import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {
  taskForm: FormGroup;
  phases;
  task;
  debounce;

  constructor(
    private dialogRef: MatDialogRef<TaskDetailComponent>,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.task = this.apiService.getTaskData();
    this.loadTaskForm(this.task);
    this.apiService.getDefaultPreCheckList({ _taskId: this.task._id }).subscribe((res) => {
      this.task.preCheckList = res.data;
      const preCheckListArray = this.taskForm.get('preCheckList') as FormArray;
      this.task.preCheckList.forEach((checkList) => {
        preCheckListArray.push(this.preCheckListFromArray(checkList));
      });
    });
    this.apiService.getDefaultPostCheckList({ _taskId: this.task._id }).subscribe((res) => {
      this.task.postCheckList = res.data;
      const postCheckListArray = this.taskForm.get('postCheckList') as FormArray;
      this.task.postCheckList.forEach((checkList) => {
        postCheckListArray.push(this.postCheckListFromArray(checkList));
      });
    });


    const subTaskArray = this.taskForm.get('subTask') as FormArray;

    if (this.task.subtask) {
      this.task.subtask.forEach((task) => {
        subTaskArray.push(this.subTaskFromArray(task));
      });
    }

  }

  loadTaskForm(task) {
    this.taskForm = new FormGroup({
      taskName: new FormControl(task.name, Validators.required),
      timeline: new FormControl(task.timeline, Validators.required),
      subTask: new FormArray([]),
      preCheckList: new FormArray([]),
      postCheckList: new FormArray([]),
      priority: new FormControl(task.priority, Validators.required),
      description: new FormControl(task.description, Validators.required)
    });
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  subTaskFromArray(task): FormGroup {
    return new FormGroup({
      name: new FormControl(task.name, Validators.required),
      timeline: new FormControl(task.timeline, Validators.required),
    });
  }

  preCheckListFromArray(checkList): FormGroup {
    return new FormGroup({
      name: new FormControl(checkList.name, Validators.required),
      _id: new FormControl(checkList._id, Validators.required),
    });
  }

  postCheckListFromArray(checkList): FormGroup {
    return new FormGroup({
      name: new FormControl(checkList.name, Validators.required),
      _id: new FormControl(checkList._id, Validators.required),
    });
  }

  addPreCheckList() {
    this.apiService.addDefaultPreCheckList({ _taskId: this.task._id }).subscribe((res) => {
      const preCheckListArray = this.taskForm.get('preCheckList') as FormArray;
      preCheckListArray.push(this.preCheckListFromArray({ name: '', _id: res.data._id }));
    });
  }

  addPostCheckList() {
    this.apiService.addDefaultPostCheckList({ _taskId: this.task._id }).subscribe((res) => {
      const preCheckListArray = this.taskForm.get('postCheckList') as FormArray;
      preCheckListArray.push(this.postCheckListFromArray({ name: '', _id: res.data._id }));
    });

  }

  addSubTask() {
    const subTaskArray = this.taskForm.get('subTask') as FormArray;
    subTaskArray.push(this.subTaskFromArray({ name: '', timeline: '' }));
  }

  preCheckListUpdate(checkList, index) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.apiService.updateDefaultPreCheckList(checkList._id, { name: checkList.name }).subscribe((res) => {
        this.matSnackBar.open('Pre-Checklist updated successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  postCheckListUpdate(checkList, index) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.apiService.updateDefaultPostCheckList(checkList._id, { name: checkList.name }).subscribe((res) => {
        this.matSnackBar.open('Post-Checklist updated successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  preCheckListDelete(checkList, index) {
    this.debounce = setTimeout(() => {
      this.apiService.deleteDefaultPreCheckList(checkList._id, {}).subscribe((res) => {
        const checklistArray = this.taskForm.get('preCheckList') as FormArray;
        checklistArray.removeAt(checklistArray.value.findIndex((p) => p._id === checkList._id));
        this.matSnackBar.open('Pre-Checklist Deleted successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  postCheckListDelete(checkList, index) {
    this.debounce = setTimeout(() => {
      this.apiService.deleteDefaultPostCheckList(checkList._id, {}).subscribe((res) => {
        const checklistArray = this.taskForm.get('postCheckList') as FormArray;
        checklistArray.removeAt(checklistArray.value.findIndex((p) => p._id === checkList._id));
        this.matSnackBar.open('Post-Checklist Deleted successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  onSelectPriority(event) {
    this.apiService.updateDefaultTask({ priority: event.value }, this.task._id).subscribe((res) => {
      this.matSnackBar.open('Priority updated successfully !', 'OK', {
        duration: 3000,
      });
    });
  }

  onChangeTimeline(timeline) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.apiService.updateDefaultTask({ timeline: timeline.value }, this.task._id).subscribe((res) => {
        this.matSnackBar.open('Timeline updated successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  onChangeDescription(description) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.apiService.updateDefaultTask({ description: description.value }, this.task._id).subscribe((res) => {
        this.matSnackBar.open('Description updated successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  onChangeTaskName(taskName) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.apiService.updateDefaultTask({ name: taskName.value }, this.task._id).subscribe((res) => {
        this.matSnackBar.open('Task name updated successfully !', 'OK', {
          duration: 3000,
        });
      });
    }, 2000);
  }

  subtaskUpdate() {
    if (this.taskForm.value.subTask && Array.isArray(this.taskForm.value.subTask) && this.taskForm.value.subTask.length) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.apiService.updateDefaultTask({ subtask: this.taskForm.value.subTask }, this.task._id).subscribe((res) => {
          this.matSnackBar.open('Subtask updated successfully !', 'OK', {
            duration: 3000,
          });
        });
      }, 2000);
    }
  }
}

