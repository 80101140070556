import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const currentUser = this.authService.currentUserValue;

        if (currentUser && currentUser.Authorization && moment(new Date()).diff(currentUser.timeStamp, 'hours') < 24) {
            // logged in
            return true;
        }

        // not logged in
        this.router.navigateByUrl('/auth/login');
        return false;
    }

}
