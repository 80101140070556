<div class="modal_parent">
    <div class="sameline_horizontal">
        <div>
            <h2>
                <strong>Change Password</strong>
            </h2>
        </div>
        <div>
            <mat-icon style="cursor: pointer" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <div class="modal_content" mat-dialog-content>
        <div>
            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>New Password</mat-label>
                            <input matInput placeholder="Password" type="password" formControlName="password"
                                required />
                        </mat-form-field>
                    </div>
                    <div class="w-100"></div>
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Confirm Password</mat-label>
                            <input matInput placeholder="Confirm Password" type="password" formControlName="cnfPassword"
                            (keyup)="passwordChecker()" required />
                        </mat-form-field>
                        <!-- <span *ngIf="formControl.cnfPassword.invalid && (formControl.cnfPassword.dirty || formControl.cnfPassword.touched) && passwordMatch" class="error xs-regular">
                            Password does not match </span> -->
                    </div>
                </div>
                <div align="center">
                    <button  class="btn_common" type="submit">Confirm</button>
                </div>
            </form>
        </div>
    </div>
</div>