import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-add-agent-modal',
  templateUrl: './add-agent-modal.component.html',
  styleUrls: ['./add-agent-modal.component.scss'],
})
export class AddAgentModalComponent implements OnInit {
  agentForm: FormGroup;

  constructor(
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddAgentModalComponent>
  ) {}

  ngOnInit(): void {
    this.loadAgentForm();
  }

  loadAgentForm() {
    this.agentForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      phone: new FormControl(''),
      password: new FormControl('', Validators.required),
      agentDefaults: new FormGroup({
        currency: new FormControl(''),
        currencyConversionToInr: new FormControl(1),
        costFactor: new FormControl(''),
      }),
    });
  }

  onAddAgent() {
    if (this.agentForm.valid) {
      const signupData = {
        ...this.agentForm.value,
        roles: ['agent'],
      };
      this.authService.createUser(signupData).subscribe((user) => {
        if (user) {
          this.matSnackBar.open('Agent added successfully !', 'OK', {
            duration: 3000,
          });
          this.dialogRef.close({ agentProp: true });
        }
      });
    }
  }
}
