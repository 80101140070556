import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-create-phase',
  templateUrl: './create-phase.component.html',
  styleUrls: ['./create-phase.component.scss']
})
export class CreatePhaseComponent implements OnInit {

  phaseForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreatePhaseComponent>
  ) {
    this.loadPhaseForm();
  }

  ngOnInit(): void {
  }

  loadPhaseForm() {
    this.phaseForm = new FormGroup({
      name: new FormControl(this.data ? this.data.name : '', Validators.required),
      timeline: new FormControl(this.data ? this.data.timeline : '', Validators.required)
    })
  }

  onAddPhase() {
    if (this.phaseForm.valid) {
      if (!this.data) {
        this.apiService.addDefaultPhase(this.phaseForm.value).subscribe((res) => {
          if (res) {
            this.matSnackBar.open('Phase added successfully !', 'OK', {
              duration: 3000,
            });
            this.dialogRef.close({ agentProp: true });
          }
        });
      } else {
        this.apiService.updateDefaultPhase(this.phaseForm.value, this.data._id).subscribe((res) => {
          if (res) {
            this.matSnackBar.open('Phase updated successfully !', 'OK', {
              duration: 3000,
            });
            this.dialogRef.close({ agentProp: true });
          }
        });
      }
    }
  }
}
