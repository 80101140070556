import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  sampleInvoiceData;
  taskData;
  projectData;

  constructor(private http: HttpClient) { }

  getToken() {
    return JSON.parse(localStorage.getItem('mUser')).Authorization;
  }

  // add customer
  addCustomer(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/customer/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list customers
  listCustomers(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/customer/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list Customer By ID
  listCustomerById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/customer/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list customer by phone
  listCustomerByPhone(phone) {
    let token = this.getToken();
    return this.http.get<any>(
      `${environment.apiUrl}/customer/fetch/phone/${phone}`,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update customer
  updateCustomer(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/customer/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add project type
  addProjectType(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/project_types/create`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list project types
  listProjectTypes() {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/project_types/list`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list project type by id
  listProjectTypeById(id) {
    let token = this.getToken();
    return this.http.get<any>(
      `${environment.apiUrl}/project_types/fetch/${id}`,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update project type
  updateProjectType(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/project_types/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add section
  addSection(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/sections/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list Sections
  listSections() {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/sections/list`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list Section By Id
  listSectionById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/sections/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update section
  updateSection(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/sections/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add product
  addProduct(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/products/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list products
  listProducts(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/products/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list Product By Id
  listProductById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/products/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update product
  updateProduct(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/products/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add hardware
  addHardware(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/hardwares/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list hardwares
  listHardwares(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/hardwares/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list Hardware By Id
  listHardwareById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/hardwares/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update hardware
  updateHardware(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/hardwares/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // bulklist hardwares
  bulkListHardwares(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/hardwares/bulklist/`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add appliance
  addAppliance(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/appliances/create`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list appliances
  listAppliances(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/appliances/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list Appliance By Id
  listApplianceById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/appliances/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update appliance
  updateAppliance(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/appliances/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // create project
  createProject(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/project/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list projects
  listProjects(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/project/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list project by ID
  listProjectById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/project/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update project
  updateProject(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/project/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // fetch order for invoice
  fetchInvoice(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/project/fetch/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getTerms(type) {
    let token = this.getToken();
    return this.http.get<any>(
      `${environment.apiUrl}/terms/${type}`,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  updateTerms(data, type) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/terms/update/${type}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list user by roles
  listUserByRole(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/auth/list/`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update user role
  updateUserRole(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/auth/updaterole`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update user role
  updatePassword(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/auth/updatepassword`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }
  // fetch inactive customer data
  fetchAnalyticsData() {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/analytics/overview`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list products(Inventory)
  listInventoryProducts(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/product/list`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // get product by id(Inevntory)
  getInventoryProductById(id) {
    let token = this.getToken();
    return this.http.get<any>(
      `${environment.apiUrl}/inventory/product/fetch/${id}`,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add product(Inventory)
  addInventoryProduct(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/product`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update product(Inventory)
  updateInventoryProduct(id, data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/product/update/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list categories(Inventory)
  listCategories(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/category/list`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add category(Inventory)
  addCategory(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/category`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update category(Inventory)
  updateCategory(id, data) {
    let token = this.getToken();
    return this.http.put<any>(
      `${environment.apiUrl}/inventory/category/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list sub-categories(Inventory)
  listSubCategories(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/subcategory/list`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add category(Inventory)
  addSubCategory(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/subcategory`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update sub-category(Inventory)
  updateSubCategory(id, data) {
    let token = this.getToken();
    return this.http.put<any>(
      `${environment.apiUrl}/inventory/subcategory/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list brands(Inventory)
  listBrands(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/brand/list`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add brand(Inventory)
  addBrand(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/inventory/brand`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update brand(Inventory)
  updateBrand(id, data) {
    let token = this.getToken();
    return this.http.put<any>(
      `${environment.apiUrl}/inventory/brand/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // list tags(Inventory)
  listTags(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/productTags/list`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // add tag(Inventory)
  addTag(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/productTags`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update tag(Inventory)
  updateTag(id, data) {
    let token = this.getToken();
    return this.http.put<any>(
      `${environment.apiUrl}/inventory/productTags/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // create inventory order
  createInventoryOrder(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/inventory/order`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // list inventory orders
  listInventoryOrders(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/order/list`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  fetchOrderById(id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/inventory/order/${id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // update inventory order
  updateInventoryOrder(id, data) {
    let token = this.getToken();
    return this.http.put<any>(
      `${environment.apiUrl}/inventory/order/${id}`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // update product status in order
  updateProductStatusInOrder(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/order/product_status`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // track product status in order
  trackProductInOrder(data) {
    let token = this.getToken();
    return this.http.post<any>(
      `${environment.apiUrl}/inventory/order/tracking`,
      data,
      {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      }
    );
  }

  // upload image
  uploadImage(file) {
    let formData = new FormData();
    formData.append('file', file);
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/image/upload`, formData, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  // delete image
  deleteImage(imageUrl) {
    const data = {
      imageUrl: imageUrl,
    };
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/image/delete`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  //getSampleInvoiceData
  getSampleInvoiceData() {
    return this.sampleInvoiceData;
  }

  //setSampleInvoiceData
  setSampleInvoiceData(data) {
    this.sampleInvoiceData = data;
  }

  //getSampleInvoiceData
  getTaskData() {
    return this.taskData;
  }

  //setSampleInvoiceData
  setTaskData(data) {
    this.taskData = data;
  }

  addDefaultPhase(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaphase/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updateDefaultPhase(data, _id) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaphase/update/${_id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deleteDefaultPhase(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaphase/delete/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  addDefaultTask(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metatask/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }
  getDefaultPhases() {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaphase/list`, {}, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getDefaultTasksByPhase(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metatask/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updateDefaultTask(data, _id) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metatask/update/${_id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deleteDefaultTask(data, _id) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metatask/delete/${_id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getDefaultPreCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaprechecklist/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getDefaultPostCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metapostchecklist/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  addDefaultPreCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaprechecklist/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  addDefaultPostCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metapostchecklist/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updateDefaultPreCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaprechecklist/update/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updateDefaultPostCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metapostchecklist/update/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deleteDefaultPreCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metaprechecklist/delete/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deleteDefaultPostCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/metapostchecklist/delete/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  startProject(body) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postProject/create`, body, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  listStartedProject(body) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postProject/list`, body, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  fetchStartedProjectData(_id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/postProject/fetch/${_id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updateStartedProjectData(data, _id) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postProject/update/${_id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getTasksByPhase(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/task/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  addTask(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/task/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updateTask(data, _id) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/task/update/${_id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deleteTask(data, _id) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/task/delete/${_id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  fetchTask(_id) {
    let token = this.getToken();
    return this.http.get<any>(`${environment.apiUrl}/task/fetch/${_id}`, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  fetchTaskByIds(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/task/list/ids`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getPreCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/prechecklist/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  getPostCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postchecklist/list`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  addPreCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/prechecklist/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  addPostCheckList(data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postchecklist/create`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updatePreCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/prechecklist/update/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  updatePostCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postchecklist/update/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deletePreCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/prechecklist/delete/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  deletePostCheckList(id, data) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/postchecklist/delete/${id}`, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }

  fetchTasks(body) {
    let token = this.getToken();
    return this.http.post<any>(`${environment.apiUrl}/task/list/`, body, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }


  getProjectData() {
    return {
      status: "Active",
      phases: [
        { _id: "1", value: "Initial", timeline: 7, isActive: true },
        { _id: "2", value: "Design", timeline: 10, isActive: false },
        { _id: "3", value: "Detailing", timeline: 11, isActive: false }],
      task: [
        {
          _id: "1", phaseId: "1", name: "Phase 1 Task 1", timeline: 8, status: "Completed", assignee: "Test User", priority: "High", dueDate: new Date(),
          startDate: new Date(), endDate: new Date(), description: "This is task 1 for phase 1",
          comments: [{ user: "Manager", value: "Okay Approved" }, { user: "Designer", value: "Not approved, changes required" }],
          preCheckList: {
            status: "Completed", value: [
              { _id: "1", value: "Phase 1 Task 1 PreCheckList 1" },
              { _id: "1", value: "Phase 1 Task 1 PreCheckList 2" },
              { _id: "1", value: "Phase 1 Task 1 PreCheckList 3" },
              { _id: "1", value: "Phase 1 Task 1 PreCheckList 4" }
            ]
          },
          postCheckList: {
            status: "Pending", value: [
              { _id: "1", value: "Phase 1 Task 1 PostCheckList 1" },
              { _id: "1", value: "Phase 1 Task 1 PostCheckList 2" },
              { _id: "1", value: "Phase 1 Task 1 PostCheckList 3" },
              { _id: "1", value: "Phase 1 Task 1 PostCheckList 4" }
            ]
          },
          subTask: [{ id: 1, value: "Phase 1 Task 1 Subtask 1", timeline: 6, status: "Completed" }]
        },
        {
          _id: "2", phaseId: "1", name: "Phase 1 Task 2", timeline: 2, status: "Not Started", assignee: "Test User", priority: "High", dueDate: new Date(),
          startDate: new Date(), endDate: new Date(), description: "This is task 2 for phase 1",
          comments: [{ user: "Manager", value: "Task is approved" }, { user: "Designer", value: "Not approved, changes required" }],
          preCheckList: {
            status: "Pending", value: [
              { _id: "1", value: "Phase 1 Task 2 PreCheckList 1" },
              { _id: "1", value: "Phase 1 Task 2 PreCheckList 2" },
              { _id: "1", value: "Phase 1 Task 2 PreCheckList 3" },
              { _id: "1", value: "Phase 1 Task 2 PreCheckList 4" }
            ]
          },
          postCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 2 PostCheckList 1" },
              { _id: "1", value: "Phase 1 Task 2 PostCheckList 2" },
              { _id: "1", value: "Phase 1 Task 2 PostCheckList 3" },
              { _id: "1", value: "Phase 1 Task 2 PostCheckList 4" }
            ]
          },
          subTask: [{ id: 1, value: "Phase 1 Task 2 Subtask 1", timeline: 6, status: "Completed" }]
        },
        {
          _id: "3", phaseId: "1", name: "Phase 1 Task 3", timeline: 2, status: "Pending", assignee: "Test User", priority: "Low", dueDate: new Date(),
          startDate: new Date(), endDate: new Date(), description: "This is task 3 for phase 1",
          comments: [{ user: "Manager", value: "Task is approved" }, { user: "Designer", value: "Not approved, changes required" }],

          preCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 3 PreCheckList 1" },
              { _id: "1", value: "Phase 1 Task 3 PreCheckList 2" },
              { _id: "1", value: "Phase 1 Task 3 PreCheckList 3" },
              { _id: "1", value: "Phase 1 Task 3 PreCheckList 4" }
            ]
          },
          postCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 3 PostCheckList 1" },
              { _id: "1", value: "Phase 1 Task 3 PostCheckList 2" },
              { _id: "1", value: "Phase 1 Task 3 PostCheckList 3" },
              { _id: "1", value: "Phase 1 Task 3 PostCheckList 4" }
            ]
          },
          subTask: [{ id: 1, value: "Phase 1 Task 3 Subtask 1", timeline: 6, status: "Completed" }]
        },
        {
          _id: "4", phaseId: "1", name: "Phase 1 Task 4", timeline: 5, status: "Not Started", assignee: "Test User", priority: "Medium", dueDate: new Date(),
          startDate: new Date(), endDate: new Date(),
          preCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 4 PreCheckList 1" },
              { _id: "1", value: "Phase 1 Task 4 PreCheckList 2" },
              { _id: "1", value: "Phase 1 Task 4 PreCheckList 3" },
              { _id: "1", value: "Phase 1 Task 4 PreCheckList 4" }
            ]
          },
          postCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 4 PostCheckList 1" },
              { _id: "1", value: "Phase 1 Task 4 PostCheckList 2" },
              { _id: "1", value: "Phase 1 Task 4 PostCheckList 3" },
              { _id: "1", value: "Phase 1 Task 4 PostCheckList 4" }
            ]
          },
          subTask: [{ id: 1, value: "Phase 1 Task 4 Subtask 1", timeline: 6, status: "Pending" }]
        },
        {
          _id: "5", phaseId: "1", name: "Phase 1 Task 5", timeline: 8, status: "Not Started", assignee: "Test User", priority: "Low", dueDate: new Date(),
          startDate: new Date(), endDate: new Date(),
          preCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 5 PreCheckList 1" },
              { _id: "1", value: "Phase 1 Task 5 PreCheckList 2" },
              { _id: "1", value: "Phase 1 Task 5 PreCheckList 3" },
              { _id: "1", value: "Phase 1 Task 5 PreCheckList 4" }
            ]
          },
          postCheckList: {
            status: "Inactive", value: [
              { _id: "1", value: "Phase 1 Task 5 PostCheckList 1" },
              { _id: "1", value: "Phase 1 Task 5 PostCheckList 2" },
              { _id: "1", value: "Phase 1 Task 5 PostCheckList 3" },
              { _id: "1", value: "Phase 1 Task 5 PostCheckList 4" }
            ]
          },
          subTask: [{ id: 1, value: "Phase 1 Task 5 Subtask 1", timeline: 6, status: "Pending" }]
        }]
    }
  }

  getStatusColor() {
    return {
      high: "#ff0000",
      low: "#ffa500",
      medium: "#e9967a",
      Completed: "#008000",
      Pending: "#ffa500",
      "Not Started": "#a9a9a9",
      preCheckListPending: "#ffa500",
      preChecklistSubmitted: "#ffa500",
      postCheckListPending: "#ffa500",
      postChecklistSubmitted: "#ffa500",
      inprogress: "#008000",
      complete: "#008000",
      notStarted: ""
    }
  }

  getStageLabel() {
    return {
      preCheckListPending: "Prechecklist Submission Pending",
      preChecklistSubmitted: "Prechecklist Approval Pending",
      postCheckListPending: "Postchecklist Submission Pending",
      postChecklistSubmitted: "Postchecklist Approval Pending",
      inprogress: "In Progress",
      complete: "Completed",
      notStarted: "Not Started"
    }
  }
  httpGet(route: string, payload?: string): Observable<any> {
    const url = `${environment.apiUrl}/${route}`;
    if (payload) {
      return this.http.get(url + payload);
    }
    return this.http.get(url);
  }

  httpPost(route: string, data: any): Observable<any> {
    let token = this.getToken();
    const url = `${environment.apiUrl}/${route}`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    });
  }
}
