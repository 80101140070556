<div class="parent">
  <div class="back">
    <div class="label">
      <mat-icon>receipt</mat-icon>Invoice
    </div>
    <!-- <button
      mat-button
      class="pdf-button align-self-baseline"
      (click)="convertToPdf()"
      *ngIf="invoiceData"
    > -->
    <div *ngIf="invoiceData">
      <button (click)="showEstimate()" class="font-weight-bold text-white bg-transparent border-0">
        View Estimate
      </button>
      &nbsp;<span class="text-white font-weight-bold">|</span>&nbsp;
      <button (click)="showSummary()" class="font-weight-bold text-white bg-transparent border-0">
        View Summary
      </button>
    </div>
    <div *ngIf="invoiceData?.totalMargin > 0 && isAdmin">
      <p style="color: white; font-size: 18px; margin-top: 6px">
        Margin :
        <span>{{
          invoiceData?.totalMargin -
          (invoiceData.finalHardwareMargin / 100) *
          invoiceData.finalHardwareTotalDiscount -
          (invoiceData.finalProductMargin / 100) *
          invoiceData.finalProductTotalDiscount
          | currency: "INR":"symbol-narrow":"2.0":"en-IN"
          }}</span>
      </p>
    </div>
    <button mat-button class="pdf-button align-self-baseline" [useExistingCss]="true"
      printTitle="{{ invoiceData.customerName }}" printSectionId="print-section" ngxPrint *ngIf="invoiceData">
      Generate PDF
    </button>
  </div>

  <div class="
      d-flex
      flex-column
      p-5
      invoice
      justify-content-between
      content
      shadow
    " *ngIf="invoiceData && !summary" id="print-section">
    <div class="invoice__top mb-5 pb-5">
      <div class="invoice__top-logo row" id="header">
        <div class="invoice__top-logo-image mt-3">
          <img src="../../assets/logo-square.png" height="40" alt="" />
        </div>
        <div class="invoice__top-logo-text row ml-3 align-items-center">
          METILLI
        </div>
      </div>
      <!-- <hr class="w-100 mt-3 mb-5 logo-hr" /> -->
      <div class="table__header-overlap"></div>
      <hr class="w-100 mt-3 mb-5 logo-hr" />
      <div class="row">
        <div class="invoice__top-left col-6 p-4">
          <!--   <div class="row">
            <p class="col-5 px-0"><b>From:</b></p>
            <p class="col-7 px-0">Metilli Bengaluru, Karnataka, India</p>
          </div> -->
          <div class="row">
            <p class="col-12 px-0 mb-4 font-weight-bold">Bill To:</p>
          </div>
          <div class="row">
            <!-- 
            <p class="col-5 px-0"><b>Client Name:</b></p> -->
            <p class="col-12 px-0">{{ invoiceData.customerName }}</p>
          </div>
          <div class="row">
            <!-- <p class="col-5 px-0"><b>Client Address:</b></p> -->
            <p class="col-12 px-0">{{ invoiceData.projectAddress }}</p>
          </div>
          <!-- <div class="row mb-4">GSTIN: GSTIN Number</div> -->
          <!-- <div class="row">
            <p class="col-5 px-0"><b>Client Mobile:</b></p>
            <p class="col-7 px-0">{{ invoiceData.phone }}</p>
          </div> -->
          <div class="row mt-2">
            <b>{{ invoiceData.projectType }} </b>
          </div>
        </div>
        <div class="
            invoice__top-right
            col-6
            d-flex
            flex-column
            justify-content-end
            pl-5
            pr-0
          ">
          <div class="row invoice__top-right-title mb-5 pb-2">QUOTE</div>

          <!-- <div class="row">
            <p class="col-5 px-0">From:</p>
            <p class="col-7 px-0"><b>Metilli Bengaluru, Karnataka, India</b></p>
          </div> -->

          <div class="row">
            <p class="col-4 px-0">Date:</p>
            <p class="col-8 px-0">
              <b>{{ invoiceData.created | date }}</b>
            </p>
          </div>
          <div class="row">
            <div class="col-4 px-0">GST No:</div>
            <div class="col-8 px-0"><b>29ABNFM2586P1Z7</b></div>
          </div>
          <!--  <div
            class="row invoice__top-right-details justify-content-between mt-5"
          >
            <div class="col-6 pl-0">
              <div class="row mb-3">Estimated Date</div>
              <div class="row"><b>15 Oct, 2020</b></div>
            </div>
            <div class="col-6">
              <div class="row mb-3">GST No.</div>
              <div class="row"><b>37529475960375726</b></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="invoice__table mb-5">
      <table class="table">
        <thead>
          <tr class="table__header-overlap">
            <th colspan="5"></th>
          </tr>
          <tr class="tableHeader">
            <th scope="col">#</th>
            <th scope="col">Item Name</th>
            <th scope="col">Description</th>
            <th scope="col">Quantity</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="invoiceData.sections">
            <ng-container *ngFor="let section of invoiceData.sections">
              <tr class="bg-light">
                <td colspan="5" class="text-uppercase text-left">
                  <b>{{ section.sectionName }}</b>
                </td>
              </tr>
              <tr *ngFor="let prod of section.products; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ prod.productName }}</td>
                <td>
                  {{ prod.productMaterial }} |
                  {{ prod.productMaterialType }}
                  <br />
                  <p *ngIf="prod.description" class="mt-2" style="color: #757575">
                    {{ prod.description }}
                  </p>
                </td>
                <td>{{ prod.count }}</td>
                <td class="pr-0 text-right price">
                  {{
                  prod.total | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                  }}
                </td>
              </tr>
              <ng-container *ngIf="section.hardwares.length != 0">
                <tr class="bg-light text-left">
                  <td colspan="5" class="text-uppercase">
                    <b>{{ section.sectionName }} Hardwares</b>
                  </td>
                </tr>
                <tr *ngFor="let hard of section.hardwares; index as i">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ hard.hardwareName }}</td>
                  <td>{{ hard.hardwareType }}</td>
                  <td>{{ hard.count }}</td>
                  <td class="pr-0 text-right">
                    {{
                    hard.total | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="section.appliances.length != 0">
                <tr class="bg-light text-left">
                  <td colspan="5" class="text-uppercase">
                    <b>{{ section.sectionName }} appliances</b>
                  </td>
                </tr>

                <tr *ngFor="let app of section.appliances; index as i">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ app.applianceName }}</td>
                  <td>{{ app.applianceType }}</td>
                  <td>{{ app.count }}</td>
                  <td class="pr-0 text-right">
                    {{
                    app.total | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}
                    &nbsp;
                    <strike *ngIf="app.discount">
                      {{
                      (app.total * 100) / (100 - app.discount)
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </strike>
                    &nbsp;
                    <small class="text-uppercase font-weight-bold text-success" *ngIf="app.discount">
                      {{ app.discount }}% OFF
                    </small>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="5" class="py-3 pr-0 text-right">
                  <span class="font-weight-bold">
                    {{ section.sectionName }}&nbsp;Total:&nbsp; &nbsp; </span>{{
                  section.total
                  | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                  }}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <tr class="px-0">
            <td colspan="5" class="px-0">
              <div class="row justify-content-between mt-5">
                <div class="col-5 pl-0">
                  <div class="row">
                    <p><b>Product Total</b></p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                      invoiceData.finalProductTotal -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                    &nbsp;
                    <strike *ngIf="invoiceData.finalProductTotalDiscount">
                      {{
                      invoiceData.finalProductTotal
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </strike>
                    &nbsp;&nbsp;
                    <small class="text-success font-weight-bold" *ngIf="invoiceData.finalProductTotalDiscount">
                      {{ invoiceData.finalProductTotalDiscount }} % OFF
                    </small>
                  </div>
                  <!-- <div class="row">
                    <p>Product Discount</p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{ invoiceData.finalProductTotalDiscount }} %
                    </p>
                  </div> -->
                  <div class="row">
                    <p><b>Hardware Total</b></p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                      invoiceData.finalHardwareTotal -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                    &nbsp;
                    <strike *ngIf="invoiceData.finalHardwareTotalDiscount">
                      {{
                      invoiceData.finalHardwareTotal
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </strike>
                    &nbsp;&nbsp;
                    <small class="text-success small font-weight-bold" *ngIf="invoiceData.finalHardwareTotalDiscount">
                      {{ invoiceData.finalHardwareTotalDiscount }} % OFF
                    </small>
                  </div>
                  <!-- <div class="row">
                    <p>Hardware Discount</p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                        invoiceData.finalHardwareTotalDiscount
                          | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                  </div> -->
                </div>
                <div class="col-4 pr-0">
                  <div class="row">
                    <p>Sub Total</p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                      invoiceData.totalAmount -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                  </div>
                  <div class="row">
                    <p>GST</p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">{{ invoiceData.gstPercent }}%</p>
                  </div>
                  <div class="row bg-dark text-white p-2" *ngIf="invoiceData.totalAmount">
                    <p class="my-0">Grand Total</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{
                      ((invoiceData.totalAmount -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100) *
                      invoiceData.gstPercent) /
                      100 +
                      (invoiceData.totalAmount -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100)
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfooter">
          <tr class="w-100">
            <td colspan="5" class="px-0 border-top-0 w-100">
              <div class="footSpace w-100"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="spacer"></div>
    <div class="lastRow">
      <table class="table">
        <tbody>
          <tr class="px-0">
            <td colspan="5" class="border-top-0 px-0">
              <div class="invoice__bottom mt-5">
                <div class="row mt-5">
                  <div class="invoice__bottom-left col-4 pl-0">
                    <div class="
                        row
                        mb-2
                        mt-4
                        font-weight-bold
                        justify-content-center
                      ">
                      Padmavathi Peddibhotla
                    </div>
                    <div class="row mb-2 justify-content-center">
                      Account Manager
                    </div>
                  </div>
                  <div class="invoice__bottom-center col-4">
                    <div class="row mb-2 justify-content-center font-weight-bold">
                      <img src="../../assets/location.svg" height="18" alt="" class="mr-1" />
                      Location
                    </div>
                    <div class="row mb-2 justify-content-center">
                      L-372, HSR Sector 6
                    </div>
                    <div class="row mb-2 justify-content-center">Banglore</div>
                  </div>
                  <div class="invoice__bottom-right col-4 pr-0">
                    <ng-container *ngIf="invoiceData.designerName">
                      <div class="row mb-2 font-weight-bold justify-content-center">
                        {{ invoiceData.designerName }}
                      </div>
                    </ng-container>
                    <div class="row mb-2 justify-content-center">
                      {{ invoiceData.desginerContact }}
                    </div>
                    <div class="row mb-2 justify-content-center">
                      {{ invoiceData.desginerEmail }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="terms">
      <div class="table__header-overlap"></div>
      <hr class="w-100 mt-3 mb-2 logo-hr" />
      <div class="row"></div>
      <div class="row justify-content-center my-3">
        <h1 class="font-weight-bold mt-3">Terms & Conditions</h1>
      </div>
      <div class="row terms-para" style="page-break-after: always" [innerHTML]="terms">
      </div>
      <div class="lastRow">
        <div class="row mr-4 font-weight-bold justify-content-end">
          Client Signature
        </div>
      </div>
    </div>


  </div>
  <div class="
      d-flex
      flex-column
      p-5
      invoice
      justify-content-between
      content
      shadow
    " *ngIf="invoiceData && summary" id="print-section">
    <div class="invoice__top mb-5 pb-5">
      <div class="parent_header">
        <div class="invoice__top-logo row" id="header">
          <div class="invoice__top-logo-image mt-3">
            <img src="../../assets/logo-square.png" height="40" alt="" />
          </div>
          <div class="invoice__top-logo-text row ml-3 align-items-center">
            METILLI
          </div>
        </div>
      </div>
      <!-- <hr class="w-100 mt-3 mb-5 logo-hr" /> -->
      <div class="table__header-overlap"></div>
      <hr class="w-100 mt-3 mb-5 logo-hr" />
      <div class="row">
        <div class="invoice__top-left col-6 p-4">
          <!--   <div class="row">
            <p class="col-5 px-0"><b>From:</b></p>
            <p class="col-7 px-0">Metilli Bengaluru, Karnataka, India</p>
          </div> -->
          <div class="row">
            <p class="col-12 px-0 mb-4 font-weight-bold">Bill To:</p>
          </div>
          <div class="row">
            <!-- 
            <p class="col-5 px-0"><b>Client Name:</b></p> -->
            <p class="col-12 px-0">{{ invoiceData.customerName }}</p>
          </div>
          <div class="row">
            <!-- <p class="col-5 px-0"><b>Client Address:</b></p> -->
            <p class="col-12 px-0">{{ invoiceData.projectAddress }}</p>
          </div>
          <!--           <div class="row">
            <p class="col-5 px-0"><b>Client Address:</b></p>
            <p class="col-7 px-0">{{ invoiceData.projectAddress }}</p>
          </div> -->
          <!-- <div class="row mb-4">GSTIN: GSTIN Number</div> -->
          <!-- <div class="row">
            <p class="col-5 px-0"><b>Client Mobile:</b></p>
            <p class="col-7 px-0">{{ invoiceData.phone }}</p>
          </div> -->
          <div class="row mt-2">
            <b>{{ invoiceData.projectType }} </b>
          </div>
        </div>
        <div class="
            invoice__top-right
            col-6
            d-flex
            flex-column
            justify-content-end
            pl-5
            pr-0
          ">
          <div class="row invoice__top-right-title mb-5 pb-2">Summary</div>

          <!-- <div class="row">
            <p class="col-5 px-0">From:</p>
            <p class="col-7 px-0"><b>Metilli Bengaluru, Karnataka, India</b></p>
          </div> -->

          <div class="row">
            <p class="col-4 px-0">Date:</p>
            <p class="col-8 px-0">
              <b> {{ invoiceData.created | date }} </b>
            </p>
          </div>
          <div class="row">
            <div class="col-4 px-0">GST No:</div>
            <div class="col-8 px-0"><b>29ABNFM2586P1Z7</b></div>
          </div>
          <!--  <div
            class="row invoice__top-right-details justify-content-between mt-5"
          >
            <div class="col-6 pl-0">
              <div class="row mb-3">Estimated Date</div>
              <div class="row"><b>15 Oct, 2020</b></div>
            </div>
            <div class="col-6">
              <div class="row mb-3">GST No.</div>
              <div class="row"><b>37529475960375726</b></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="invoice__table my-5">
      <table class="table">
        <thead>
          <tr class="table__header-overlap">
            <th colspan="5"></th>
          </tr>
          <tr class="tableHeader">
            <th scope="col">#</th>
            <th scope="col">Section Name</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="invoiceData.sections">
            <ng-container *ngFor="let section of invoiceData.sections; index as i">
              <tr>
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  {{ section.sectionName }}
                </td>
                <td>
                  {{
                  section.total
                  | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                  }}
                </td>
              </tr>
              <!-- 
              <tr *ngFor="let prod of section.products; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ prod.productName }}</td>
                <td>{{ prod.total }}</td>
              </tr> -->
            </ng-container>
          </ng-container>
          <tr class="px-0">
            <td colspan="5" class="px-0">
              <div class="row justify-content-between mt-5">
                <div class="col-5 pl-0">
                  <!--  <div class="row mb-2">Payment Method:</div>
            <div class="row mb-2"><b>Account No.</b>39334900993</div>
            <div class="row mb-2"><b>IFSC</b> SBIN0012345</div>
            <div class="row mb-2">
              <b>Make all checks payable to MetilliBlr</b>
            </div> -->

                  <div class="row">
                    <p><b>Product Total</b></p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                      invoiceData.finalProductTotal -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                    &nbsp;
                    <strike *ngIf="invoiceData.finalProductTotalDiscount">
                      {{
                      invoiceData.finalProductTotal
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </strike>
                    &nbsp;&nbsp;
                    <small class="text-success font-weight-bold" *ngIf="invoiceData.finalProductTotalDiscount">
                      {{ invoiceData.finalProductTotalDiscount }} % OFF
                    </small>
                  </div>
                  <!-- <div class="row">
              <p>Product Discount</p>
              &nbsp;&nbsp;
              <p class="ml-auto">
                {{ invoiceData.finalProductTotalDiscount }} %
              </p>
            </div> -->
                  <div class="row">
                    <p><b>Hardware Total</b></p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                      invoiceData.finalHardwareTotal -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                    &nbsp;
                    <strike *ngIf="invoiceData.finalHardwareTotalDiscount">
                      {{
                      invoiceData.finalHardwareTotal
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </strike>
                    &nbsp;&nbsp;
                    <small class="text-success small font-weight-bold" *ngIf="invoiceData.finalHardwareTotalDiscount">
                      {{ invoiceData.finalHardwareTotalDiscount }} % OFF
                    </small>
                  </div>
                </div>
                <div class="col-4 pr-0">
                  <div class="row">
                    <p>Sub Total</p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">
                      {{
                      invoiceData.totalAmount -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100 | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                  </div>
                  <div class="row">
                    <p>GST</p>
                    &nbsp;&nbsp;
                    <p class="ml-auto">{{ invoiceData.gstPercent }}%</p>
                  </div>
                  <div class="row bg-dark text-white p-2" *ngIf="invoiceData.totalAmount">
                    <p class="my-0">Grand Total</p>
                    &nbsp;&nbsp;
                    <p class="my-0 ml-auto">
                      {{
                      ((invoiceData.totalAmount -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100) *
                      invoiceData.gstPercent) /
                      100 +
                      (invoiceData.totalAmount -
                      (invoiceData.finalHardwareTotal *
                      invoiceData.finalHardwareTotalDiscount) /
                      100 -
                      (invoiceData.finalProductTotal *
                      invoiceData.finalProductTotalDiscount) /
                      100)
                      | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfooter">
          <tr class="w-100">
            <td colspan="5" class="px-0 border-top-0 w-100">
              <div class="footSpace w-100"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="spacer"></div>
    <div class="lastRow">
      <table class="table">
        <tbody>
          <tr class="px-0">
            <td colspan="5" class="border-top-0 px-0">
              <div class="invoice__bottom mt-5">
                <div class="row mt-5">
                  <div class="invoice__bottom-left col-4 pl-0">
                    <div class="
                        row
                        mb-2
                        mt-4
                        font-weight-bold
                        justify-content-center
                      ">
                      Padmavathi Peddibhotla
                    </div>
                    <div class="row mb-2 justify-content-center">
                      Account Manager
                    </div>
                  </div>
                  <div class="invoice__bottom-center col-4">
                    <div class="row mb-2 justify-content-center font-weight-bold">
                      <img src="../../assets/location.svg" height="18" alt="" class="mr-1" />
                      Location
                    </div>
                    <div class="row mb-2 justify-content-center">
                      L-372, HSR Sector 6
                    </div>
                    <div class="row mb-2 justify-content-center">Banglore</div>
                  </div>
                  <div class="invoice__bottom-right col-4 pr-0">
                    <ng-container *ngIf="invoiceData.designerName">
                      <div class="row mb-2 font-weight-bold justify-content-center">
                        {{ invoiceData.designerName }}
                      </div>
                    </ng-container>
                    <div class="row mb-2 justify-content-center">
                      {{ invoiceData.desginerContact }}
                    </div>
                    <div class="row mb-2 justify-content-center">
                      {{ invoiceData.desginerEmail }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bg-dark py-3 mt-3 footer divFooter w-100" style="page-break-after: always"></div>
    <div class="terms">
      <div class="table__header-overlap"></div>
      <hr class="w-100 mt-3 mb-2 logo-hr" />
      <div class="row"></div>
      <div class="row justify-content-center my-3">
        <h1 class="font-weight-bold mt-3">Terms & Conditions</h1>
      </div>
      <div class="row terms-para" style="page-break-after: always" [innerHTML]="terms">
      </div>
      <div class="lastRow">
        <div class="row mr-4 font-weight-bold justify-content-end">
          Client Signature
        </div>
      </div>
    </div>
  </div>
</div>