<div *ngIf="!isLoading" class="parent">
  <div class="back">
    <div class="label">
      <mat-icon>create</mat-icon>{{ projectID ? "Edit" : "New" }} Project
    </div>
    <div *ngIf="totalProjectAmount > 0">
      <p style="color: white; font-size: 18px; margin-top: 6px">
        Total Amount :
        <span>{{
          totalProjectAmount -
            (totalHardwareAmount / 100) * hardwareDiscount.value -
            (totalProductsAmount / 100) * productsDiscount.value +
            (totalProjectAmount -
              (totalHardwareAmount / 100) * hardwareDiscount.value -
              (totalProductsAmount / 100) * productsDiscount.value) *
              (projectGst.value / 100)
            | currency: "INR":"symbol-narrow":"2.0":"en-IN"
        }}</span>
      </p>
    </div>
    <div *ngIf="totalProjectMargin > 0 && isAdmin">
      <p style="color: white; font-size: 18px; margin-top: 6px">
        Total Margin :
        <span>{{
          totalProjectMargin -
            (totalHardwareMargin / 100) * hardwareDiscount.value -
            (totalProductsMargin / 100) * productsDiscount.value
            | currency: "INR":"symbol-narrow":"2.0":"en-IN"
        }}</span>
      </p>
    </div>
    <div *ngIf="increasedProductPricePercent > 0 && isAdmin">
      <p style="color: white; font-size: 18px; margin-top: 6px">
        Product Price Increase :
        <span>{{ increasedProductPricePercent }}%</span>
      </p>
    </div>
    <div class="btn" *ngIf="showSectionDiv">
      <button mat-raised-button [matMenuTriggerFor]="menu">
        Select Section
        <mat-icon class="user-image-icon">keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          *ngFor="let s of sections; let i = index"
          (click)="
            onAddSection(
              s,
              createProjectForm.get('sections').value.length,
              'after'
            )
          "
        >
          {{ s.name }}
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="content">
    <div class="col-md-12">
      <mat-horizontal-stepper linear="true" #stepper>
        <mat-step [stepControl]="createProjectForm">
          <form [formGroup]="createProjectForm">
            <ng-template matStepLabel> Client Details </ng-template>
            <div class="row">
              <div class="col-md">
                <mat-form-field>
                  <mat-label>Customer Name</mat-label>
                  <mat-select
                    placeholder="Select Customer"
                    formControlName="customerID"
                    (selectionChange)="onSelectCustomer($event)"
                    required
                  >
                    <mat-option *ngFor="let c of customers" [value]="c._id">
                      {{ c.firstName }} {{ c.lastName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field>
                  <mat-label>Mobile</mat-label>
                  <input
                    matInput
                    placeholder="Mobile Number"
                    type="number"
                    autocomplete="off"
                    formControlName="phone"
                    (keyup)="onPopulateCustomer($event.target.value)"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field>
                  <mat-label>House Type</mat-label>
                  <mat-select
                    placeholder="Select House Type"
                    formControlName="projectTypeID"
                    (selectionChange)="onSelectProjectType($event)"
                    required
                  >
                    <mat-option *ngFor="let t of types" [value]="t._id">{{
                      t.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="w-100"></div>
              <div class="col-md">
                <mat-form-field>
                  <mat-label>Designer</mat-label>
                  <!-- <input
                    matInput
                    placeholder="Enter Designer Name"
                    formControlName="designerName"
                    required
                  /> -->
                  <mat-select 
                    placeholder="Select Designer" 
                    formControlName="designerID" 
                    (selectionChange)="onSelectDesigner($event)"
                    required
                  >
                    <mat-option *ngFor="let d of designers" [value]="d._id">
                      {{ d.firstName }} {{ d.lastName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field>
                  <mat-label>Designer Contact</mat-label>
                  <input
                    matInput
                    placeholder="Designer Contact"
                    type="number"
                    formControlName="desginerContact"
                    autocomplete="off"
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-md">
                <mat-form-field>
                  <mat-label>Designer Email</mat-label>
                  <input
                    matInput
                    placeholder="Designer Email"
                    formControlName="desginerEmail"
                    autocomplete="off"
                    readonly
                    required
                  />
                </mat-form-field>
              </div>
              <div class="w-100"></div>
              <div class="col-md">
                <mat-form-field>
                  <mat-label>Project Address</mat-label>
                  <mat-select
                    placeholder="Select Project Address"
                    formControlName="projectAddressID"
                    required
                  >
                    <mat-option
                      *ngFor="let a of customerAddresses"
                      [value]="a._id"
                      (click)="onSelectAddress(a)"
                    >
                      {{ a.building }}{{ a.street ? ", " + a.street : ""
                      }}{{ a.locality ? ", " + a.locality : ""
                      }}{{ a.landmark ? ", " + a.landmark : "" }}, PIN:
                      {{ a.pin }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="createProjectForm.get('sections').value.length > 0">
              <mat-divider></mat-divider>

              <mat-tab-group mat-stretch-tabs class="w-100 py-3">
                <mat-tab
                  formArrayName="sections"
                  *ngFor="
                    let sRow of createProjectForm.get('sections')['controls'];
                    let i = index
                  "
                >
                  <ng-template mat-tab-label>
                    {{ sRow.value.sectionName }}
                    <div>
                      <mat-icon
                        class="mx-2 mt-2"
                        style="font-size: 18px"
                        (click)="onRemoveSection(i)"
                        >cancel
                      </mat-icon>
                    </div>
                  </ng-template>
                  <div [formGroupName]="i">
                    <mat-accordion>
                      <mat-expansion-panel
                        class="mx-1 my-2 rounded"
                        formArrayName="products"
                        *ngFor="
                          let pRow of createProjectForm.get([
                            'sections',
                            i,
                            'products'
                          ])['controls'];
                          let j = index
                        "
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{
                              pRow.value.productName
                                ? pRow.value.productName
                                : "Add Product"
                            }}
                            <div>
                              <mat-icon
                                class="mx-2"
                                style="font-size: 18px; margin-top: 2px"
                                (click)="onRemoveProduct(i, j)"
                                >cancel
                              </mat-icon>
                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [formGroupName]="j">
                          <div class="row">
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Select Product</mat-label>
                                <mat-select
                                  placeholder="Select Product"
                                  formControlName="productID"
                                  (selectionChange)="
                                    onSelectProduct($event, i, j)
                                  "
                                  required
                                >
                                  <mat-option
                                    *ngFor="let p of products"
                                    [value]="p._id"
                                  >
                                    {{ p.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div
                              class="col-md d-flex justify-content-center imageDiv"
                            >
                              <p
                                class="ml-3"
                                *ngIf="pRow.value.image"
                                (click)="openImageModal(pRow.value.image)"
                              >
                                Image
                              </p>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Select Material</mat-label>
                                <mat-select
                                  placeholder="Select Material"
                                  formControlName="productMaterial"
                                  required
                                >
                                  <mat-option
                                    *ngFor="
                                      let material of pRow.value.materials
                                    "
                                    [value]="material.name"
                                    (click)="onSelectMaterial(material, i, j)"
                                  >
                                    {{ material.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Select Material Type</mat-label>
                                <mat-select
                                  placeholder="Select Material Type"
                                  formControlName="productMaterialType"
                                  required
                                >
                                  <mat-option
                                    *ngFor="
                                      let materiaType of pRow.value
                                        .materialTypes
                                    "
                                    [value]="materiaType.name"
                                    (click)="
                                      onSelectType(
                                        materiaType,
                                        i,
                                        j,
                                        'products'
                                      )
                                    "
                                  >
                                    {{ materiaType.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-select
                                  placeholder="Select Unit"
                                  formControlName="unit"
                                  (selectionChange)="
                                    onSelectUnit($event, pRow.value, i, j)
                                  "
                                >
                                  <!-- <mat-option value="mtr">MTR.</mat-option> -->
                                  <mat-option value="ft">FT.</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Height (FT.)</mat-label>
                                <input
                                  matInput
                                  placeholder="Height"
                                  type="number"
                                  formControlName="height"
                                  (keyup)="
                                    onSetTotal(pRow.value, i, j, 'products')
                                  "
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Width (FT.)</mat-label>
                                <input
                                  matInput
                                  placeholder="Width"
                                  type="number"
                                  formControlName="width"
                                  (keyup)="
                                    onSetTotal(pRow.value, i, j, 'products')
                                  "
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Count</mat-label>
                                <input
                                  matInput
                                  placeholder="Count"
                                  type="number"
                                  formControlName="count"
                                  (keyup)="
                                    onSetTotal(pRow.value, i, j, 'products')
                                  "
                                />
                              </mat-form-field>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-md" *ngIf="isAdmin">
                              <mat-form-field>
                                <mat-label>Price</mat-label>
                                <input
                                  matInput
                                  placeholder="Price"
                                  type="number"
                                  formControlName="price"
                                  required
                                  readonly
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-md" *ngIf="isAdmin">
                              <mat-form-field>
                                <mat-label>Margin</mat-label>
                                <input
                                  matInput
                                  placeholder="Margin"
                                  type="number"
                                  formControlName="commission"
                                  required
                                  readonly
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>M.R.P</mat-label>
                                <input
                                  matInput
                                  placeholder="M.R.P"
                                  type="number"
                                  formControlName="mrp"
                                  required
                                  readonly
                                />
                              </mat-form-field>
                            </div>
                            <!-- <div class="col-md">
                                <mat-form-field>
                                  <mat-label>Tax</mat-label>
                                  <input matInput placeholder="Tax" type="number" formControlName="tax">
                                </mat-form-field>
                              </div> -->
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Total</mat-label>
                                <input
                                  matInput
                                  placeholder="Total"
                                  type="number"
                                  formControlName="total"
                                  required
                                  readonly
                                />
                              </mat-form-field>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Description</mat-label>
                                <input
                                  matInput
                                  placeholder="Description"
                                  type="text"
                                  formControlName="description"
                                />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <div class="row justify-content-center mt-3 w-100">
                      <button
                        type="button"
                        mat-button
                        class="stepper-button"
                        (click)="onAddProduct('', i)"
                      >
                        Add Product
                      </button>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>

              <mat-divider></mat-divider>
            </div>
            <div class="row justify-content-center mt-3">
              <button
                type="button"
                mat-button
                matStepperNext
                class="stepper-button"
                (click)="onNextStep('hardwareStep')"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="createProjectForm">
          <form [formGroup]="createProjectForm">
            <ng-template matStepLabel>Hardwares</ng-template>
            <mat-tab-group mat-stretch-tabs class="w-100 py-3">
              <mat-tab
                formArrayName="sections"
                *ngFor="
                  let sRow of createProjectForm.get('sections')['controls'];
                  let i = index
                "
              >
                <ng-template mat-tab-label>
                  {{ sRow.value.sectionName }}
                </ng-template>
                <div [formGroupName]="i">
                  <div class="productTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product</th>
                          <th>Material</th>
                          <th>Material Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let p of createProjectForm.get([
                              'sections',
                              i,
                              'products'
                            ]).value;
                            let k = index
                          "
                        >
                          <td>
                            <img
                              [src]="'https:' + p.image"
                              alt="image"
                              style="width: 50px; height: 50px"
                            />
                          </td>
                          <td>{{ p.productName }}</td>
                          <td>{{ p.productMaterial }}</td>
                          <td>{{ p.productMaterialType }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <mat-divider></mat-divider>
                  <mat-accordion>
                    <mat-expansion-panel
                      class="mx-1 my-2 rounded"
                      formArrayName="hardwares"
                      *ngFor="
                        let hRow of createProjectForm.get([
                          'sections',
                          i,
                          'hardwares'
                        ])['controls'];
                        let j = index
                      "
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{
                            hRow.value.hardwareName
                              ? hRow.value.hardwareName
                              : "Add Hardware"
                          }}
                          <div>
                            <mat-icon
                              class="mx-2"
                              style="font-size: 18px; margin-top: 2px"
                              (click)="onRemoveHardware(i, j)"
                              >cancel
                            </mat-icon>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div [formGroupName]="j">
                        <div class="row">
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Select Hardware</mat-label>
                              <mat-select
                                placeholder="Select Hardware"
                                formControlName="hardwareID"
                                (selectionChange)="
                                  onSelectHardware($event, i, j)
                                "
                                required
                              >
                                <mat-option
                                  *ngFor="let h of hardwares"
                                  [value]="h._id"
                                >
                                  {{ h.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="col-md d-flex justify-content-center imageDiv"
                          >
                            <p
                              class="ml-3"
                              *ngIf="hRow.value.image"
                              (click)="openImageModal(hRow.value.image)"
                            >
                              Image
                            </p>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Select Hardware Type</mat-label>
                              <mat-select
                                placeholder="Select Hardware Type"
                                formControlName="hardwareType"
                              >
                                <mat-option
                                  *ngFor="let hType of hRow.value.hardwareTypes"
                                  [value]="hType.name"
                                  (click)="
                                    onSelectType(hType, i, j, 'hardwares')
                                  "
                                >
                                  {{ hType.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Height</mat-label>
                              <input
                                matInput
                                placeholder="Height"
                                type="number"
                                formControlName="height"
                                (keyup)="
                                  onSetTotal(hRow.value, i, j, 'hardwares')
                                "
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Width</mat-label>
                              <input
                                matInput
                                placeholder="Width"
                                type="number"
                                formControlName="width"
                                (keyup)="
                                  onSetTotal(hRow.value, i, j, 'hardwares')
                                "
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Count</mat-label>
                              <input
                                matInput
                                placeholder="Count"
                                type="number"
                                formControlName="count"
                                (keyup)="
                                  onSetTotal(hRow.value, i, j, 'hardwares')
                                "
                              />
                            </mat-form-field>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-md" *ngIf="isAdmin">
                            <mat-form-field>
                              <mat-label>Price</mat-label>
                              <input
                                matInput
                                placeholder="Price"
                                type="number"
                                formControlName="price"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md" *ngIf="isAdmin">
                            <mat-form-field>
                              <mat-label>Margin</mat-label>
                              <input
                                matInput
                                placeholder="Margin"
                                type="number"
                                formControlName="commission"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>M.R.P</mat-label>
                              <input
                                matInput
                                placeholder="M.R.P"
                                type="number"
                                formControlName="mrp"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-md">
                              <mat-form-field>
                                <mat-label>Tax</mat-label>
                                <input matInput placeholder="Tax" type="number" formControlName="tax">
                              </mat-form-field>
                            </div> -->
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Total</mat-label>
                              <input
                                matInput
                                placeholder="Total"
                                type="number"
                                formControlName="total"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Associate Product</mat-label>
                              <mat-select
                                placeholder="Associate Product"
                                formControlName="productID"
                                (selectionChange)="
                                  onAssociateProduct($event, i, j)
                                "
                              >
                                <mat-option
                                  *ngFor="
                                    let p of createProjectForm.get([
                                      'sections',
                                      i,
                                      'products'
                                    ]).value
                                  "
                                  [value]="p.productID"
                                >
                                  {{ p.productName }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <div class="row justify-content-center mt-3 w-100">
                    <button
                      type="button"
                      mat-button
                      class="stepper-button"
                      (click)="onAddHardware('', i)"
                    >
                      Add Hardware
                    </button>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <div class="row justify-content-center mt-3">
              <button
                type="button"
                mat-button
                class="stepper-button"
                (click)="onBackStep('hardwareStep', stepper)"
              >
                Back
              </button>
              <button
                type="button"
                mat-button
                matStepperNext
                class="stepper-button ml-3"
                (click)="onNextStep('expenseStep')"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <!-- <mat-step [stepControl]="createProjectForm">
          <form [formGroup]="createProjectForm">
            <ng-template matStepLabel>Appliances</ng-template>
            <mat-tab-group mat-stretch-tabs class="w-100 py-3">
              <mat-tab
                formArrayName="sections"
                *ngFor="
                  let sRow of createProjectForm.get('sections')['controls'];
                  let i = index
                "
              >
                <ng-template mat-tab-label>
                  {{ sRow.value.sectionName }}
                </ng-template>
                <div [formGroupName]="i">
                  <div class="productTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product</th>
                          <th>Material</th>
                          <th>Material Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let p of createProjectForm.get([
                              'sections',
                              i,
                              'products'
                            ]).value;
                            let k = index
                          "
                        >
                          <td>
                            <img
                              [src]="'https:' + p.image"
                              alt="image"
                              style="width: 50px; height: 50px"
                            />
                          </td>
                          <td>{{ p.productName }}</td>
                          <td>{{ p.productMaterial }}</td>
                          <td>{{ p.productMaterialType }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <mat-divider></mat-divider>
                  <mat-accordion>
                    <mat-expansion-panel
                      class="mx-1 my-2 rounded"
                      formArrayName="appliances"
                      *ngFor="
                        let aRow of createProjectForm.get([
                          'sections',
                          i,
                          'appliances'
                        ])['controls'];
                        let j = index
                      "
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{
                            aRow.value.applianceName
                              ? aRow.value.applianceName
                              : "Add Appliance"
                          }}
                          <div>
                            <mat-icon
                              class="mx-2"
                              style="font-size: 18px; margin-top: 2px"
                              (click)="onRemoveAppliance(i, j)"
                              >cancel
                            </mat-icon>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div [formGroupName]="j">
                        <div class="row">
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Select Appliance</mat-label>
                              <mat-select
                                placeholder="Select Appliance"
                                formControlName="applianceID"
                                (selectionChange)="
                                  onSelectAppliance($event, i, j)
                                "
                                required
                              >
                                <mat-option
                                  *ngFor="let h of appliances"
                                  [value]="h._id"
                                >
                                  {{ h.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="col-md d-flex justify-content-center imageDiv"
                          >
                            <p
                              class="ml-3"
                              *ngIf="aRow.value.image"
                              (click)="openImageModal(aRow.value.image)"
                            >
                              Image
                            </p>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Select Appliance Type</mat-label>
                              <mat-select
                                placeholder="Select Appliance Type"
                                formControlName="applianceType"
                              >
                                <mat-option
                                  *ngFor="
                                    let aType of aRow.value.applianceTypes
                                  "
                                  [value]="aType.name"
                                  (click)="
                                    onSelectType(aType, i, j, 'appliances')
                                  "
                                >
                                  {{ aType.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Height</mat-label>
                              <input
                                matInput
                                placeholder="Height"
                                type="number"
                                formControlName="height"
                                (keyup)="
                                  onSetTotal(aRow.value, i, j, 'appliances')
                                "
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Width</mat-label>
                              <input
                                matInput
                                placeholder="Width"
                                type="number"
                                formControlName="width"
                                (keyup)="
                                  onSetTotal(aRow.value, i, j, 'appliances')
                                "
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Count</mat-label>
                              <input
                                matInput
                                placeholder="Count"
                                type="number"
                                formControlName="count"
                                (keyup)="
                                  onSetTotal(aRow.value, i, j, 'appliances')
                                "
                              />
                            </mat-form-field>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-md" *ngIf="isAdmin">
                            <mat-form-field>
                              <mat-label>Price</mat-label>
                              <input
                                matInput
                                placeholder="Price"
                                type="number"
                                formControlName="price"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md" *ngIf="isAdmin">
                            <mat-form-field>
                              <mat-label>Margin</mat-label>
                              <input
                                matInput
                                placeholder="Margin"
                                type="number"
                                formControlName="commission"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>M.R.P</mat-label>
                              <input
                                matInput
                                placeholder="M.R.P"
                                type="number"
                                formControlName="mrp"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                          // this div was commented when appliances was there
                            <div class="col-md">
                              <mat-form-field>
                                <mat-label>Tax</mat-label>
                                <input matInput placeholder="Tax" type="number" formControlName="tax">
                              </mat-form-field>
                            </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Discount(%)</mat-label>
                              <input
                                matInput
                                placeholder="Discount"
                                type="number"
                                min="0"
                                formControlName="discount"
                                (keyup)="setTotalOnDicount(aRow.value, i, j)"
                              />
                              <mat-error *ngIf="!aRow.get('discount').valid"
                                >{{ errorMsg }}
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-md">
                            <mat-form-field>
                              <mat-label>Total</mat-label>
                              <input
                                matInput
                                placeholder="Total"
                                type="number"
                                formControlName="total"
                                required
                                readonly
                              />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <div class="row justify-content-center mt-3 w-100">
                    <button
                      type="button"
                      mat-button
                      class="stepper-button"
                      (click)="onAddAppliance('', i)"
                    >
                      Add Appliance
                    </button>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <div class="row justify-content-center mt-3">
              <button
                type="button"
                mat-button
                class="stepper-button"
                (click)="onBackStep('applianceStep', stepper)"
              >
                Back
              </button>
              <button
                type="button"
                mat-button
                matStepperNext
                class="stepper-button ml-3"
                (click)="onNextStep('expenseStep')"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>  -->
        <mat-step [stepControl]="expenseForm">
          <form [formGroup]="expenseForm">
            <ng-template matStepLabel>Expenses</ng-template>
            <div class="sameline_horizontal">
              <div>
                <h2><strong>Add Expenses</strong></h2>
              </div>
              <div>
                <mat-icon style="cursor: pointer" (click)="onAddExpenseRow()"
                  >add_box</mat-icon
                >
              </div>
            </div>
            <div
              formArrayName="expenses"
              *ngFor="
                let eRow of expenseForm.get('expenses')['controls'];
                let i = index
              "
            >
              <div [formGroupName]="i">
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Name</mat-label>
                      <input
                        matInput
                        placeholder="Enter Expense Name"
                        formControlName="name"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Description</mat-label>
                      <input
                        matInput
                        placeholder="Enter Expense Description"
                        formControlName="description"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field>
                      <mat-label>Price</mat-label>
                      <input
                        matInput
                        placeholder="Enter Expense Price"
                        type="number"
                        formControlName="price"
                        (keyup)="calculateExpenseTotal()"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 d-flex justify-content-center mt-3">
                    <mat-icon
                      style="cursor: pointer"
                      (click)="onRemoveExpenseRow(i)"
                      >delete</mat-icon
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-4 text-align-center"
              *ngIf="totalProjectMargin > 0 && totalExpense > 0"
            >
              <div class="row">
                <div class="col-md-3 d-flex justify-content-center">
                  <p style="font-size: 18px">
                    Total Margin :
                    <span>{{
                      totalProjectMargin -
                        (totalHardwareMargin / 100) * hardwareDiscount.value -
                        (totalProductsMargin / 100) * productsDiscount.value
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="col-md-1 d-flex justify-content-center">
                  <p style="font-size: 18px">
                    <strong>-</strong>
                  </p>
                </div>
                <div class="col-md-3 d-flex justify-content-center">
                  <p style="font-size: 18px">
                    Total Expense :
                    <span>{{
                      totalExpense
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="col-md-2 d-flex justify-content-center">
                  <p style="font-size: 18px">
                    <strong>=</strong>
                  </p>
                </div>
                <div class="col-md-3 d-flex justify-content-center">
                  <p style="font-size: 18px">
                    <span>{{
                      totalProjectMargin -
                        (totalHardwareMargin / 100) * hardwareDiscount.value -
                        (totalProductsMargin / 100) * productsDiscount.value -
                        totalExpense
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="row">
                <div class="col-md-4 d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    Total Products Amount :
                    <span>{{
                      totalProductsAmount
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <strong>+</strong>
                  </p>
                </div>
                <div class="col-md" *ngIf="isAdmin">
                  <mat-form-field>
                    <mat-label>Product Price Increase (%)</mat-label>
                    <input 
                      matInput 
                      placeholder="Increase Product Price" 
                      type="number" 
                      min="0" 
                      formControlName="increasedProductPricePercent"
                      (keyup)="onProductPriceIncreaseChange($event.target.value)"
                    />
                  </mat-form-field>
                </div>
                <div class="col-md d-flex justify-content-center mt-4" *ngIf="isAdmin">
                  <p style="font-size: 18px">
                    <strong>-</strong>
                  </p>
                </div>
                <div class="col-md">
                  <mat-form-field>
                    <mat-label>Discount On Products(%)</mat-label>
                    <input
                      matInput
                      placeholder="Discount"
                      type="number"
                      formControlName="finalProductTotalDiscount"
                      min="0"
                      #productsDiscount
                    />
                    <mat-error
                      *ngIf="
                        !expenseForm.get('finalProductTotalDiscount').valid
                      "
                      >{{ errorMsg }}</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <strong>=</strong>
                  </p>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <span>{{
                      totalProductsAmount -
                        (totalProductsAmount / 100) * productsDiscount.value
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4 d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    Total Hardwares Amount :
                    <span>{{
                      totalHardwareAmount
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <strong>-</strong>
                  </p>
                </div>
                <div class="col-md">
                  <mat-form-field>
                    <mat-label>Discount on Hardwares(%)</mat-label>
                    <input
                      matInput
                      placeholder="Discount"
                      type="number"
                      formControlName="finalHardwareTotalDiscount"
                      min="0"
                      #hardwareDiscount
                    />
                    <mat-error
                      *ngIf="
                        !expenseForm.get('finalHardwareTotalDiscount').valid
                      "
                      >{{ errorMsg }}</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <strong>=</strong>
                  </p>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <span>{{
                      totalHardwareAmount -
                        (totalHardwareAmount / 100) * hardwareDiscount.value
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4 d-flex justify-content-center mt-4">
                  <p style="font-size: 18px; margin-top: 6px">
                    Total :
                    <span>{{
                      totalProjectAmount -
                        (totalHardwareAmount / 100) * hardwareDiscount.value -
                        (totalProductsAmount / 100) * productsDiscount.value
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <strong>+</strong>
                  </p>
                </div>
                <div class="col-md">
                  <mat-form-field>
                    <mat-label>GST(%)</mat-label>
                    <input
                      matInput
                      placeholder="GST"
                      type="number"
                      formControlName="gstPercent"
                      min="0"
                      #projectGst
                    />
                  </mat-form-field>
                </div>
                <div class="col-md d-flex justify-content-center mt-4">
                  <p style="font-size: 18px">
                    <strong>=</strong>
                  </p>
                </div>
                <div class="col-md-4 d-flex justify-content-center mt-4">
                  <p style="font-size: 18px; margin-top: 6px">
                    Project Total :
                    <span>{{
                      totalProjectAmount -
                        (totalHardwareAmount / 100) * hardwareDiscount.value -
                        (totalProductsAmount / 100) * productsDiscount.value +
                        (totalProjectAmount -
                          (totalHardwareAmount / 100) * hardwareDiscount.value -
                          (totalProductsAmount / 100) *
                            productsDiscount.value) *
                          (projectGst.value / 100)
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <button
                type="button"
                matStepperPrevious
                mat-button
                class="stepper-button"
              >
                Back
              </button>
              <button
                type="submit"
                mat-button
                class="stepper-button ml-3"
                (click)="onCreateProject()"
              >
                {{ projectID ? "Update" : "Create" }}
              </button>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
  <!-- <div class="content content__side col-md-3 mx-0">
      <div class="col-md-12">
        <div class="content__side-heading mb-3 text-uppercase row justify-content-center">
          Select Section
        </div>
        <div class="contetnt__side-para text-uppercase">
          <mat-action-list>
            <button mat-list-item *ngFor="let s of sections; let i = index" (click)="onAddSection(s, i)">
              {{ s.name }}
            </button>
          </mat-action-list>
        </div>
      </div>
    </div> -->
</div>
<div
  *ngIf="isLoading"
  class="row h-100 justify-content-center align-items-center"
>
  <mat-spinner></mat-spinner>
</div>