<div class="modal_parent">
  <div class="sameline_horizontal">
    <div>
      <h2>
        <strong>Generate Invoice</strong>
      </h2>
    </div>
    <div>
      <mat-icon style="cursor: pointer" [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <div class="modal_content" mat-dialog-content>
    <div>
      <form [formGroup]="generateForm" (ngSubmit)="onGenerate()">
        <div class="row">
          <div class="col-md">
            <!-- <mat-form-field>
              <mat-label>Customer Name</mat-label>
              <mat-select
                placeholder="Select Customer"
                formControlName="customerID"
                (selectionChange)="onSelectCustomer($event)"
                required
              >
                <mat-option *ngFor="let c of customers" [value]="c._id">
                  {{ c.firstName }} {{ c.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field>
              <mat-label>Enter Customer</mat-label>
              <input type="text" placeholder="Enter Customer" matInput formControlName="customerName"
                [matAutocomplete]="searchCustomer" />
              <mat-autocomplete #searchCustomer="matAutocomplete" (optionSelected)="onSelectCustomer($event)"
                [displayWith]="displayCustomer">
                <hr style="margin: 4px" />
                <mat-option *ngFor="let c of customers" [value]="c._id">
                  {{ c.firstName }} {{ c.lastName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md">
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input matInput placeholder="Enter Address" type="text" autocomplete="off"
                formControlName="customerAddress" required />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <mat-form-field>
              <mat-label>Select Products</mat-label>
              <input type="text" placeholder="Enter Product" matInput formControlName="searchControl"
                [matAutocomplete]="search" />
              <mat-autocomplete #search="matAutocomplete" (optionSelected)="onSelectProduct($event)"
                [displayWith]="displayFn">
                <hr style="margin: 4px" />
                <mat-option *ngFor="let p of products" [value]="p">
                  {{ p.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <ng-container *ngIf="this.generateForm.get('products').value.length > 0">
              <hr />
              <div class="productTable">
                <table>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Image</th>
                      <th>Product</th>
                      <th>M.R.P</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Discount</th>
                      <th>T.M.R.P</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr formArrayName="products" *ngFor="
                        let pRow of generateForm.get('products')['controls'];
                        let i = index
                      ">
                      <td>{{ i + 1 }}</td>
                      <td>
                        <img *ngIf="pRow.value.image" [src]="'https:' + pRow.value.image" alt="image"
                          style="width: 50px; height: 50px" />
                        <p *ngIf="!pRow.value.image">NA</p>
                      </td>
                      <td>{{ pRow.value.name }}</td>
                      <td>
                        {{
                        pRow.value.mrp
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                        }}
                      </td>
                      <td>
                        {{
                        pRow.value.price
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                        }}
                      </td>
                      <td [formGroupName]="i">
                        <input type="number" formControlName="quantity" required style="width: 50px"
                          (change)="onCalculateTotals(i)" />
                      </td>
                      <td [formGroupName]="i">
                        <input type="number" formControlName="discount" required style="width: 50px"
                          (change)="onCalculateTotals(i)" />
                      </td>
                      <td>
                        {{
                        pRow.value.totalMrp
                        | currency: "INR":"symbol-narrow":"2.0":"en-IN"
                        }}
                      </td>
                      <td>
                        <mat-icon (click)="onRemove(pRow.value.productID)">delete</mat-icon>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
            </ng-container>
          </div>
        </div>
        <div class="d-flex justify-content-around" *ngIf="generateForm.value.totalAmount">
          <div class="d-flex flex-column">
            <label for="globalDiscount">Global Discount</label>
            <input id="globalDiscount" type="number" required style="width: 100px"
              (blur)="applyGlobalDiscount($event)" />
          </div>

          <div class="d-flex flex-column">
            <label for="paidAmount">Paid Amount</label>
            <input id="paidAmount" formControlName="paidAmount" type="number" style="width: 100px"
              (keyup)="applyPaidAmount($event)" />
          </div>
          <p>
            Remaining Amount :
            <strong>
              {{
              generateForm.value.totalAmount - paidAmount
              | currency: "INR":"symbol-narrow":"2.0":"en-IN"
              }}
            </strong>
          </p>
          <p>
            Total Amount :
            <strong>
              {{
              generateForm.value.totalAmount
              | currency: "INR":"symbol-narrow":"2.0":"en-IN"
              }}
            </strong>
          </p>
        </div>
        <div align="center">
          <button class="btn_common" type="submit" [mat-dialog-close]="true">
            Create
          </button>
        </div>
      </form>
    </div>
  </div>
</div>