import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CreateProjectComponent } from './create-project/create-project.component';
import { SampleInvoiceComponent } from './inventory/sample-invoice/sample-invoice.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ListProjectsComponent } from './list-projects/list-projects.component';
import { UserManagementComponent } from './user-management/user-management.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'product-management',
    loadChildren: () =>
      import('./product-management/product-management.module').then(
        (m) => m.ProductManagementModule
      ),
  },
  {
    path: 'project-management',
    loadChildren: () =>
      import('./project-management/project-management.module').then(
        (m) => m.ProjectManagementModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: 'inventory-orders',
    loadChildren: () =>
      import('./inventory-orders/inventory-orders.module').then(
        (m) => m.InventoryOrdersModule
      ),
  },
  {
    path: 'create-project',
    component: CreateProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-project/:id',
    component: CreateProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'list-projects',
    component: ListProjectsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice/:id',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'salesInvoice',
    component: SampleInvoiceComponent,
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./admin-settings/admin-settings.module').then((m) => m.AdminSettingsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
