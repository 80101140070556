import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { GenerateModalComponent } from '../generate-modal/generate-modal.component';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-sample-invoice',
  templateUrl: './sample-invoice.component.html',
  styleUrls: ['./sample-invoice.component.scss'],
})
export class SampleInvoiceComponent implements OnInit, OnDestroy {
  invoiceData;
  date;
  terms;
  totalAmount = 0;
  totalDiscount = 0;
  totalPrice = 0;
  totalGst = 0;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.setData();
  }

  ngOnInit(): void { }

  setData() {
    const currentUser = this.authService.currentUserValue;
    this.date = new Date();
    const data = this.apiService.getSampleInvoiceData();

    if (!data) this.router.navigateByUrl('inventory');
    this.totalAmount = data.totalAmount.toFixed();
    this.invoiceData = {
      customerID: data.customerID,
      customerName: data.customerName,
      address: data.customerAddress,
      totalAmount: data.totalAmount.toFixed(),
      designerName: currentUser.firstName + ' ' + currentUser.lastName,
      designerContact: currentUser.phone,
      designerEmail: currentUser.email,
      paidAmount: data.paidAmount,
      sections: data.products.map((p) => {
        // this.totalAmount = this.totalAmount + (p.totalMrp + p.totalMrp * (18 / 100));
        this.totalDiscount = this.totalDiscount + (p.discount * ((p.price * p.quantity) / 100));
        this.totalPrice = this.totalPrice + (p.price * p.quantity);
        this.totalGst = this.totalGst + (((p.price * p.quantity) - (p.discount * ((p.price * p.quantity) / 100))) * (18 / 100));
        return {
          productID: p.productID,
          productName: p.name,
          image: p.image,
          description: p.description,
          quantity: p.quantity,
          mrp: p.mrp.toFixed(),
          discount: parseFloat(p.discount).toFixed(2),
          total: p.totalMrp.toFixed(),
          gst: 18,
          price: p.price.toFixed(),
          // finalAmount: (p.totalMrp + p.totalMrp * (18 / 100)).toFixed(),
        };
      }),
    };
    this.apiService.getTerms('inventory').subscribe((res) => {
      if (res && res.data && Array.isArray(res.data) && res.data[0] && res.data[0].value) {
        this.terms = res.data[0].value;
      }
    });
  }

  openGenerateModal() {
    let dialogRef;
    dialogRef = this.dialog.open(GenerateModalComponent);
    dialogRef.afterClosed().subscribe((res) => {
      this.totalAmount = 0;
      this.totalDiscount = 0;
      this.totalPrice = 0;
      this.totalGst = 0;
      this.setData();
    });
  };

  ngOnDestroy(): void {
    this.apiService.setSampleInvoiceData(undefined);
  }

  exportAsPDF(div_id) {
    let pdf = new jspdf('p', 'cm', 'a4'); //Generates PDF in landscape mode
    let data = document.getElementById(div_id);
    let termsData = document.getElementById("terms");
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    html2canvas(data, { scale: 2 }).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/jpeg');
      pdf.addImage(contentDataURL, 'JPEG', 0, 0, width, height, "alias1", "FAST");
      html2canvas(termsData, {
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('terms').style.display = 'block';
          clonedDoc.getElementById('terms').style.visibility = 'visible';
        }, scale: 2
      }).then(canvas2 => {
        const contentDataURL = canvas2.toDataURL('image/png')
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 1, 0, width -2, height -2, "alias2", "SLOW");
        pdf.save('Quote.pdf');
      });
    });

  }
}
